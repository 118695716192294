import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonComponentsModule } from './components/common/common-components.module';
import { FooterQuestionComponent } from './components/common/footer-question/footer-question.component';
import { HeaderQuestionComponent } from './components/common/header-question/header-question.component';
import { QuestionNpsComponent } from './components/survey/question-nps/question-nps.component';
import { QuestionOptionsChoiseComponent } from './components/survey/question-options-choise/question-options-choise.component';
import { SurveyModule } from './components/survey/survey.module';
import { PipesModule } from './pipes/pipes.module';

@NgModule({
  declarations: [
    AppComponent,
    QuestionNpsComponent,
    QuestionOptionsChoiseComponent,
    HeaderQuestionComponent,
    FooterQuestionComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PipesModule,
    SurveyModule,
    CommonComponentsModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
