import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  OnInit,
} from '@angular/core';
import { Option } from '../../../../models/survey/option/option';
import { makeCanSelectLanguageUseCase } from '../../../../usecases/language/can-select-language/can-select-language-use-case-factory';
import { QuestionComponent } from '../question/question.component';

@Component({
  selector: 'app-question-options-choise',
  templateUrl: './question-options-choise.component.html',
  styleUrls: ['./question-options-choise.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionOptionsChoiseComponent
  extends QuestionComponent
  implements OnInit
{
  private canSelectLanguageUseCase = makeCanSelectLanguageUseCase();

  public canSelectLanguage = false;
  public list: Option[] = [];

  constructor(injector: Injector) {
    super(injector);
  }

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();
    this.canSelectLanguage = await this.canSelectLanguageUseCase.handle();
    for (const opt of this.question.options) {
      this.answer?.selectedOptions.map(async (optSelected) => {
        if (opt.id === optSelected.id) {
          opt.selected = true;
          return;
        }
      });
    }

    this.question.options.forEach((option) => {
      this.list.push(option);
    });
    this.detectChanges();
  }

  async selectOption(option: Option): Promise<void> {
    option.selected
      ? await super.onUnselectOption(option)
      : await super.onSelectOption(option);
    this.detectChanges();
  }

  async sendResponse(): Promise<void> {
    await this.onSave();
  }

  override close() {
    super.close(this.answer);
  }

  public backgroundColor(option: Option) {
    if (option.selected) {
      return option.backgroundColorSelected || '#333333';
    } else {
      return '';
    }
  }

  public color(option: Option) {
    if (option.selected) {
      return option.textColorSelected || '#ffffff';
    } else {
      return '';
    }
  }

  public boxShadow(option: Option) {
    if (option.selected) {
      return 'none';
    } else {
      return '';
    }
  }
}
