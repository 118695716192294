import { makeSurveyRepository } from '../../repositories/survey/survey-repository-factory';
import { makeLogService } from '../common/log/log/log-service-factory';
import { makeFetchSurveyService } from '../net/fetch-survey/fetch-survey-service-factory';
import { IDownloadSurveyService } from './download-survey-service';
import { DownloadSurveyServiceImpl } from './download-survey-service-impl';

export function makeDownloadSurveyService(): IDownloadSurveyService {
  const logService = makeLogService();
  const fetchSurveyService = makeFetchSurveyService();
  const surveyRepository = makeSurveyRepository();
  return new DownloadSurveyServiceImpl(
    logService,
    fetchSurveyService,
    surveyRepository,
  );
}
