import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  OnInit,
} from '@angular/core';
import { LanguageDict } from '../../../../models/common/language-dict/language-dict';
import { SuperDynamicComponent } from './../../../helpers/super-dynamic-component';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent extends SuperDynamicComponent implements OnInit {
  public title: LanguageDict;

  constructor(injector: Injector) {
    super(injector);
  }

  async ngOnInit(): Promise<void> {
    this.title = this.params.title;
    this.detectChanges();
  }
}
