import { SuperDynamicComponent } from './../../../helpers/super-dynamic-component';
import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundComponent extends SuperDynamicComponent {
  constructor(public injector: Injector) {
    super(injector);
  }
}
