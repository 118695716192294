import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  OnInit,
} from '@angular/core';
import { LanguageDict } from '../../../../models/common/language-dict/language-dict';
import { makeFinishSurveySessionUseCase } from '../../../../usecases/survey/finish-survey-session/finish-survey-session-use-case-factory';
import { SuperDynamicComponent } from '../../../helpers/super-dynamic-component';

@Component({
  selector: 'app-finish',
  templateUrl: './finish.component.html',
  styleUrls: ['./finish.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinishComponent extends SuperDynamicComponent implements OnInit {
  public title: LanguageDict;
  public subtitle: LanguageDict;
  private canClose = false;

  private finishAnswerSessionUseCase = makeFinishSurveySessionUseCase();

  constructor(injector: Injector) {
    super(injector);
  }

  async ngOnInit(): Promise<void> {
    this.title = this.params.title;
    this.subtitle = this.params.subtitle;
    this.detectChanges();

    setTimeout(async () => {
      this.processedThenClose();
    }, 3e3);

    await this.finishAnswerSessionUseCase.handle();
    this.canClose = true;
  }

  processedThenClose(times = 0) {
    if (this.canClose) {
      this.close();
    } else {
      if (times > 3) {
        this.close();
      } else {
        setTimeout(async () => {
          this.processedThenClose(times++);
        }, 1e3);
      }
    }
  }
}
