/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { makeEnvironment } from '../../environments/make-environment';
import { CustomError } from '../error/custom-error';
import { timeoutPromise } from '../promise/timeout-promise';
import { HttpFetch } from './http-fetch';

export class HttpFetchImpl implements HttpFetch {
  private useUrl: string;
  private useMethod = 'GET';
  private useHeaders: any = { 'Content-Type': 'application/json' };
  private useLocalWS = false;
  private useTimeoutMS = 0;
  private useCheckStatus = 0;
  private useResponseType = 'json';
  private useBody: any;
  private useCache: RequestCache = 'default';
  environment = makeEnvironment();

  constructor(url: string) {
    this.useUrl = url;
  }
  exec(url: string): HttpFetch {
    return HttpFetchImpl.exec(url);
  }

  static exec(url: string) {
    return new HttpFetchImpl(url);
  }

  useWS(): this {
    this.useLocalWS = true;
    return this;
  }

  queryParams(params: any): this {
    this.useUrl += this.serialize(params);
    return this;
  }

  path(path: string): this {
    this.useUrl += path;
    return this;
  }

  timeout(ms: number): this {
    this.useTimeoutMS = ms;
    return this;
  }

  method(method: string): this {
    this.useMethod = method?.toUpperCase();
    return this;
  }

  headers(headers: any): this {
    this.useHeaders = headers;
    return this;
  }

  checkStatus(status: number): this {
    this.useCheckStatus = status;
    return this;
  }

  body(body: any): this {
    this.useBody = body;
    return this;
  }

  returnTypeJSON(): this {
    this.useResponseType = 'json';
    return this;
  }

  returnTypeText(): this {
    this.useResponseType = 'text';
    return this;
  }

  returnTypeJSONXML(): this {
    this.useResponseType = 'jsonxml';
    return this;
  }

  notUseCache() {
    this.useCache = 'no-cache';
    return this;
  }

  async fetch(): Promise<any> {
    let response: Response;
    try {
      let promise: Promise<Response>;
      if (!this.useLocalWS || (await !this.isAvailableWS())) {
        promise = this.fetchDirect();
      } else {
        promise = this.fetchUseWS();
      }

      response = await timeoutPromise(this.useTimeoutMS || 10000, promise);

      if (this.useCheckStatus) {
        if (response.status !== this.useCheckStatus) {
          let error: any;
          try {
            error = await response.json();
          } catch (error) {
            //
          }
          throw CustomError.generic([
            'HttpFetch Error',
            `Unexpected status ${this.useCheckStatus}`,
            `Received status: ${response?.status}`,
            error,
          ]);
        }
      }

      switch (this.useResponseType) {
        case 'json':
          return response.json();
        case 'jsonxml':
          return response.json();
        case 'text':
          return response.text();
      }
    } catch (error) {
      if (error instanceof CustomError) {
        throw error;
      }
      throw CustomError.generic([
        'HttpFetch Error',
        { error, fetchData: this },
        `Received status: ${response?.status}`,
      ]);
    }
  }

  private fetchDirect(): Promise<Response> {
    return fetch(this.useUrl, {
      method: this.useMethod,
      headers: this.useHeaders,
      body: this.useBody,
      cache: this.useCache,
    });
  }

  private fetchUseWS(): Promise<Response> {
    return fetch(this.environment.OS_INTERFACE_URL + '/fetch', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        URL: this.useUrl,
        method: this.useMethod,
        headers: this.useHeaders,
        body: this.useBody,
        type: this.useResponseType === 'jsonxml' ? 'xml' : this.useResponseType,
      }),
    });
  }

  private serialize(queryParams: any) {
    const str = [];
    for (const p in queryParams) {
      if (queryParams.hasOwnProperty(p)) {
        if (`${queryParams[p]}`) {
          str.push(
            encodeURIComponent(p) + '=' + encodeURIComponent(queryParams[p]),
          );
        }
      }
    }
    return str.length ? '?' + str.join('&') : '';
  }

  async isAvailableWS(): Promise<boolean> {
    try {
      const result = await fetch(
        this.environment.OS_INTERFACE_URL + '/version-application',
      );
      return result?.status === 200;
    } catch (e) {
      return false;
    }
  }
}
