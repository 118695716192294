import { ISurveyRepository } from './survey-repository';
import { SurveyRepositoryImpl } from './survey-repository-impl';
import { makeIndexedDB } from '../core/indexed-db-factory';

let surveyRepository: ISurveyRepository;

export function makeSurveyRepository() {
  if (!surveyRepository) {
    surveyRepository = new SurveyRepositoryImpl(makeIndexedDB());
  }
  return surveyRepository;
}
