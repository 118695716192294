/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentRef } from '@angular/core';

export enum InactivityAction {
  LEAVE_AND_RESET = 'LEAVE_AND_RESET',
  LEAVE_AND_NOTIFY_PARENT = 'LEAVE_AND_NOTIFY_PARENT',
  ASK_LEAVE_AND_RESET = 'ASK_LEAVE_AND_RESET',
  ASK_LEAVE_AND_NOTIFY_PARENT = 'ASK_LEAVE_AND_NOTIFY_PARENT',
}
export interface IInactivityService {
  startInactivityTracking(
    action: InactivityAction,
    parentRequesting?: boolean,
  ): Promise<void>;
  haveParentRequest: boolean;
  componentRef: ComponentRef<any>;
  stopInactivityTracking(): Promise<void>;
}
