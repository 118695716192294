import { ILogService } from '../log-service';
import { LogServiceImpl } from './log-service-impl';

let logService: ILogService;

export function makeLogService() {
  if (!logService) {
    logService = new LogServiceImpl();
  }
  return logService;
}
