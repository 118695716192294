import { makeLogService } from '../../../data/services/common/log/log/log-service-factory';
import { makeSynchronizeAnswerService } from '../../../data/services/net/synchronize-answer/synchronize-answer-service-factory';
import { makeSurveyAnswerSessionService } from '../../../data/services/survey-answers-session/survey-answers-session-service-factory';
import { FinishSurveySessionUseCase } from './finish-survey-session-use-case';

export function makeFinishSurveySessionUseCase() {
  const surveyAnswerSessionService = makeSurveyAnswerSessionService();
  const syncAnswerService = makeSynchronizeAnswerService();

  return new FinishSurveySessionUseCase(
    makeLogService(),
    surveyAnswerSessionService,
    syncAnswerService,
  );
}
