import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { makeIframeCommunicationService } from '../../../../data/services/common/iframe-communication/iframe-communication-service-factory';
import { ActionIFrame } from '../../../../data/services/common/iframe-communication/iframe-communication-service-impl';
import { makeLogService } from '../../../../data/services/common/log/log/log-service-factory';
import { makeUpdateForceSurveyUseCase } from '../../../../usecases/update-survey/update-force-survey/update-force-survey-use-case-factory';

@Component({
  selector: 'app-survey',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateSurveyPageComponent implements OnInit {
  private logService = makeLogService();
  private updateForceSurveyUseCase = makeUpdateForceSurveyUseCase();
  private iframeCommunicationService = makeIframeCommunicationService();

  constructor() {
    this.logService.debug('UpdateSurveyPageComponent -> constructor');
  }

  async ngOnInit(): Promise<void> {
    await this.logService.debug('UpdateSurveyPageComponent -> start');
    try {
      await this.updateForceSurveyUseCase.handle();
      this.iframeCommunicationService.sendToParent({
        action: ActionIFrame.SURVEY_UPDATE_COMPLETED,
      });
    } catch (err) {
      await this.logService.error('UpdateSurveyPageComponent -> error: ', err);
      this.iframeCommunicationService.sendToParent({
        action: ActionIFrame.SURVEY_UPDATE_ERROR,
      });
    }
  }
}
