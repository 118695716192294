/* eslint-disable @typescript-eslint/no-explicit-any */
import { LoaderComponent } from '../../../../app/components/common/loader/loader.component';
import { DynamicComponent } from '../../../../app/helpers/dynamic-component';
import { ILoaderService } from '../../../../data/services/common/loader/loader-service';

export class LoaderServiceImpl implements ILoaderService {
  open(data?: any) {
    const dynamic = DynamicComponent.openComponent(LoaderComponent, data);
    return {
      close: () => {
        DynamicComponent.close(dynamic.componentRef);
      },
      updateMessage: (data: any) => {
        dynamic.componentRef.instance.params = data;
      },
    };
  }
}
