export enum EventTopic {
  TO_DO_LIST_CHANGED,
  TO_DO_ITEM_TO_EDIT,
  LANGUAGE_CHANGED,
  INIT_SURVEY,
  RESET_SURVEY_ON_FINISH,
  USER_ITERACTED_WITH_THE_APP,
  START_UPDATE,
  STOP_UPDATE,
}
