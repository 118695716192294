/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentRef, Injector } from '@angular/core';
import { DynamicComponent, IDynamicComponent } from './dynamic-component';
import { SuperComponent } from './super-component';

export class SuperDynamicComponent
  extends SuperComponent
  implements IDynamicComponent
{
  constructor(injector: Injector) {
    super(injector);
  }

  componentRef: ComponentRef<IDynamicComponent>;
  params?: any;

  close(result?: any) {
    DynamicComponent.close(this.componentRef, result);
  }
}
