/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentRef } from '@angular/core';
import { TimeoutComponent } from '../../../app/components/common/timeout/timeout.component';
import { DynamicComponent } from '../../../app/helpers/dynamic-component';
import { environment } from '../../../environments/environment';
import { EventTopic } from '../../../models/common/event-topic/event-topic';
import { StartUpdateUseCase } from '../../../usecases/update-survey/start-update-survey/start-update-use-case';
import { StopUpdateSurveyUseCase } from '../../../usecases/update-survey/stop-update-survey/stop-update-survey-use-case';
import { IEventService } from '../common/event/event-service';
import { ILogService } from '../common/log/log-service';
import { IInactivityService, InactivityAction } from './inactivity-service';

export class InactivityServiceImpl implements IInactivityService {
  private timeoutApplicationTime: number;
  public haveParentRequest = false;
  public componentRef: ComponentRef<any>;
  private action = InactivityAction.ASK_LEAVE_AND_RESET;

  constructor(
    private logService: ILogService,
    private eventService: IEventService,
    private startUpdateUseCase: StartUpdateUseCase,
    private stopUpdateUseCase: StopUpdateSurveyUseCase,
  ) {
    this.timeoutApplicationTime = environment.inactivityTimeOut || 15e3;
    this.replaceUserInteractionListener();
  }

  private replaceUserInteractionListener() {
    this.eventService.subscribe(
      EventTopic.USER_ITERACTED_WITH_THE_APP,
      () => {
        this.resetInactivityTracking(this.action);
      },
      true,
    );
  }

  private async resetInactivityTracking(
    action: InactivityAction,
  ): Promise<void> {
    if (!action) {
      return;
    }

    this.clearTimeout();

    globalThis.inactivityTracking = window.setTimeout(async () => {
      switch (action) {
        case InactivityAction.ASK_LEAVE_AND_RESET:
          DynamicComponent.openComponent(TimeoutComponent, {
            action: InactivityAction.LEAVE_AND_RESET,
          }).onClose((closeDynamicComponent) => {
            if (closeDynamicComponent) {
              DynamicComponent.close(this.componentRef);
            }
          });
          break;

        case InactivityAction.ASK_LEAVE_AND_NOTIFY_PARENT:
          DynamicComponent.openComponent(TimeoutComponent, {
            action: InactivityAction.LEAVE_AND_NOTIFY_PARENT,
          }).onClose((closeDynamicComponent) => {
            if (closeDynamicComponent) {
              DynamicComponent.close(this.componentRef);
            }
          });
          break;
      }
      this.stopInactivityTracking();
    }, this.timeoutApplicationTime);
  }

  async startInactivityTracking(
    action: InactivityAction = InactivityAction.ASK_LEAVE_AND_RESET,
    parentRequesting?: false,
  ): Promise<void> {
    await this.logService.info(
      'InactivityServiceImpl.startInactivityTracking -> starting inactivity tracking',
    );
    await this.logService.debug(
      'InactivityServiceImpl.startInactivityTracking -> action',
      action,
    );
    await this.stopUpdateUseCase.handle();
    if (parentRequesting) {
      this.haveParentRequest = parentRequesting;
    }
    this.replaceUserInteractionListener();
    this.action = action;
    await this.resetInactivityTracking(action);
  }

  async stopInactivityTracking(): Promise<void> {
    await this.logService.info(
      'InactivityServiceImpl.stopInactivityTracking -> stopping inactivity tracking',
    );
    await this.startUpdateUseCase.handle();
    this.eventService.subscribe(
      EventTopic.USER_ITERACTED_WITH_THE_APP,
      null,
      true,
    );
    this.clearTimeout();
  }

  private clearTimeout() {
    window.clearTimeout(globalThis.inactivityTracking);
    globalThis.inactivityTracking = null;
  }
}
