import { makeLogService } from '../../../data/services/common/log/log/log-service-factory';
import { makeRouterService } from '../../../data/services/view/router/router-service-factory';
import { makeEnvironment } from '../../../environments/make-environment';
import { makeGetSurveyIdUseCase } from '../../survey/get-survey-id/get-survey-id-use-case-factory';
import { makeGetSurveyUseCase } from '../../survey/get-survey/get-survey-use-case-factory';
import { makeSetCurrentLanguageUseCase } from '../set-current-language/set-current-language-use-case-factory';
import { InitLanguageUseCase } from './init-language-use-case';

export function makeInitLanguageUseCase() {
  return new InitLanguageUseCase(
    makeLogService(),
    makeSetCurrentLanguageUseCase(),
    makeEnvironment(),
    makeRouterService(),
    makeGetSurveyUseCase(),
    makeGetSurveyIdUseCase(),
  );
}
