import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '../../pipes/pipes.module';
import { CommonComponentsModule } from '../common/common-components.module';
import { FinishComponent } from './finish/finish.component';
import { QuestionComponent } from './question/question.component';
import { BannerComponent } from './start/banner/banner.component';
import { StartComponent } from './start/start.component';

@NgModule({
  declarations: [
    StartComponent,
    QuestionComponent,
    FinishComponent,
    BannerComponent,
  ],
  imports: [CommonModule, PipesModule, CommonComponentsModule],
})
export class SurveyModule {}
