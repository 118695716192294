import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';
import { makeIframeCommunicationService } from '../../../data/services/common/iframe-communication/iframe-communication-service-factory';
import { ActionIFrame } from '../../../data/services/common/iframe-communication/iframe-communication-service-impl';

@Injectable({
  providedIn: 'root',
})
export class TimeGuard implements CanActivate {
  private iframeCommunicationService = makeIframeCommunicationService();

  constructor(private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot): boolean | UrlTree {
    const currentDateParam = next.queryParamMap.get('currentDate');

    if (currentDateParam) {
      const currentDate = parseInt(currentDateParam);
      const tenMinutesAgo = Date.now() - 10 * 60 * 1000;

      if (currentDate >= tenMinutesAgo && currentDate <= Date.now()) {
        return true;
      }
    }

    this.iframeCommunicationService.sendToParent({
      action: ActionIFrame.SURVEY_UPDATE_ERROR,
    });
    return false;
  }
}
