import { ILogService } from '../../../data/services/common/log/log-service';
import { CustomError } from '../../../helpers/error/custom-error';
import { DownloadSurveyUseCase } from '../../survey/download-survey/download-survey-use-case';
import { GetSurveyIdUseCase } from '../../survey/get-survey-id/get-survey-id-use-case';

export class UpdateForceSurveyUseCase {
  constructor(
    private logService: ILogService,
    private downloadSurveyUseCase: DownloadSurveyUseCase,
    private getSurveyIdUseCase: GetSurveyIdUseCase,
  ) {}

  async handle(): Promise<void> {
    try {
      await this.logService.info('UpdateForceSurveyUseCase -> start');
      const surveyId = await this.getSurveyIdUseCase.handle();
      await this.downloadSurveyUseCase.handle({ id: surveyId });
    } catch (error) {
      throw CustomError.handleError('UpdateForceSurveyUseCase', error);
    } finally {
      await this.logService.info('UpdateForceSurveyUseCase -> end');
    }
  }
}
