import { HttpFetchImpl } from '../../../../helpers/http-fetch/http-fetch-impl';
import { AppEnvironment } from '../../../../models/common/environment/app-environment';
import { ILogService } from '../../common/log/log-service';
import { LoadSurveyDto } from '../../download-survey/dto/load-survey-dtos';
import { IFetchSurveyService } from './fetch-survey-service';

export class FetchSurveyServiceImpl implements IFetchSurveyService {
  constructor(
    private logService: ILogService,
    private environment: AppEnvironment,
  ) {}

  async fetchSurvey(id: string): Promise<LoadSurveyDto> {
    await this.logService.debug('FetchSurveyServiceImpl.fetchSurvey');

    const surveyFetch = await HttpFetchImpl.exec(
      `${this.environment.SURVEY_CDN}`,
    );

    const file = `${id}.json`;
    const surveyJson = await surveyFetch
      .path(`/data/survey/${file}`)
      .method('GET')
      .checkStatus(200)
      .returnTypeJSON()
      .notUseCache()
      .fetch();

    await this.logService.debug(
      'FetchSurveyServiceImpl.fetchSurvey -> surveyJson',
      surveyJson,
    );

    return surveyJson;
  }
}
