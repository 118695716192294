/* eslint-disable @typescript-eslint/no-explicit-any */
import { NavigationEnd, Params } from '@angular/router';
import { filter } from 'rxjs/operators';
import { CustomInjector } from '../../../../app/helpers/custom-injector';
import { IRouterService } from './router-service';

export class RouterServiceImpl implements IRouterService {
  private previousUrl: string;
  private currentUrl: string;

  constructor() {
    this.previousUrl = '';
    this.currentUrl = '';
    CustomInjector?.router?.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.previousUrl = this.currentUrl;
          this.currentUrl = event.url;
        }
      });
  }

  async navigate(url: any[], options?: any): Promise<void> {
    await CustomInjector.router.navigate(url, options);
  }

  getQueryParam(param: string): string {
    return CustomInjector.activatedRoute.snapshot.queryParams[param];
  }

  getParam(param: string): string {
    return CustomInjector.activatedRoute.snapshot.params[param];
  }

  getCurrentRouterPath(): string {
    return CustomInjector.activatedRoute?.snapshot?.url[0]?.path;
  }

  getPreviousUrl(): string {
    return this.previousUrl;
  }

  clearQueryParam(): void {
    const qParams: Params = {};
    this.navigate([], {
      relativeTo: this.getCurrentRouterPath(),
      queryParams: qParams,
      queryParamsHandling: '',
    });
  }

  onNavigationEnd(cb: () => void): void {
    CustomInjector?.router?.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          cb();
        }
      });
  }
}
