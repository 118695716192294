import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  OnInit,
} from '@angular/core';
import { Initial } from '../../../../models/survey/initial/initial';
import { SuperDynamicComponent } from '../../../helpers/super-dynamic-component';

@Component({
  selector: 'app-start',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StartComponent extends SuperDynamicComponent implements OnInit {
  public initial: Initial;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.initial = this.params.initial;
  }

  override close() {
    super.close();
  }
}
