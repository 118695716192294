import { HttpFetchImpl } from '../../../../helpers/http-fetch/http-fetch-impl';
import { AppEnvironment } from '../../../../models/common/environment/app-environment';
import { SurveyAnswerSession } from '../../../../models/survey/survey-answers/survey-answers-session';
import { ISurveyAnswerSessionRepository } from '../../../repositories/survey-answers-session/survey-answers-session-repository';
import { IAnswerRepository } from '../../../repositories/survey/answer/answer-repository';
import { ILogService } from '../../common/log/log-service';
import { ISynchronizeAnswerService } from './synchronize-answer-service';

export class SynchronizeAnswerServiceImpl implements ISynchronizeAnswerService {
  constructor(
    private logService: ILogService,
    private surveyAnswerSessionRepository: ISurveyAnswerSessionRepository,
    private answersRepository: IAnswerRepository,
    private env: AppEnvironment,
  ) {}

  private readonly MAX_SESSIONS_TO_SYNC = 10;

  async synchronizeAnswer(): Promise<void> {
    try {
      const doneSessions =
        await this.surveyAnswerSessionRepository.getDoneSessions(
          this.MAX_SESSIONS_TO_SYNC,
        );

      if (!doneSessions.length) {
        return;
      }

      const response = await this.formatJson(doneSessions);
      const token = this.env.TOKEN;
      const surveyFetch = HttpFetchImpl.exec(this.env.SURVEY_WS);

      await surveyFetch
        .path('/answer-session')
        .method('POST')
        .headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        })
        .body(JSON.stringify(response))
        .checkStatus(201)
        .returnTypeJSON()
        .fetch();

      await this.clearSessionsAndAnswer(doneSessions);
    } catch (error) {
      await this.logService.error(error, 'SynchronizeAnswerServiceImpl');
    }
  }

  private async formatJson(sessions: SurveyAnswerSession[]) {
    const surveyAnswer = sessions?.map((session) => {
      const answers = session.answers?.map((answer) => {
        return {
          id: answer.id,
          answerSessionId: answer.sessionId,
          questionId: answer.questionId,
          skip: false,
          done: true,
          options:
            answer.selectedOptions?.map((option) => {
              return {
                answerId: answer.id,
                id: option.id,
                value: option.value,
              };
            }) || [],
        };
      });

      return {
        id: session.id,
        surveyId: session.surveyId,
        initDate: session.initDate,
        endDate: session.endDate,
        lastDoneQuestionId: session.lastQuestionId,
        leave: session.leave,
        leaveQuestionId:
          session.leaveQuestionId == '' ? null : session.leaveQuestionId,
        origin: 'SelfCheckout',
        answers,
      };
    });

    return surveyAnswer;
  }

  private async clearSessionsAndAnswer(doneSessions: SurveyAnswerSession[]) {
    if (doneSessions?.length) {
      await this.answersRepository.deleteListBySessionId(
        doneSessions.map((session) => session.id),
      );
      await this.surveyAnswerSessionRepository.deleteList(
        doneSessions.map((session) => session.id),
      );
    }
  }

  private async clearSessionsWithoutAnswer() {
    const doneSessionsWithoutAnswer =
      await this.surveyAnswerSessionRepository.getDoneSessionsWithoutAnswer(10);

    if (doneSessionsWithoutAnswer?.length) {
      await this.surveyAnswerSessionRepository.deleteList(
        doneSessionsWithoutAnswer.map((session) => session.id),
      );
    }
  }
}
