import { makeLogService } from '../log/log/log-service-factory';
import { ITranslateService } from './translate-service';
import { TranslateServiceImpl } from './translate-service-impl';

let translateService: ITranslateService;

export function makeTranslateService() {
  if (!translateService) {
    translateService = new TranslateServiceImpl(makeLogService());
  }
  return translateService;
}
