import { LanguageKey } from './../../../../main/translate/language-key/language-key';
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

export enum ActionType {
  SUCCESS = 'success',
  BASIC = 'basic',
  CANCEL = 'cancel',
}

export class Action {
  desc: LanguageKey;
  type: ActionType;
  extraInfo?: any;
}

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionsComponent {
  @Input()
  public actions: Action[] = [];

  @Input()
  public isEnabled = true;

  @Output()
  public clickAction = new EventEmitter<Action>();

  onClickAction(action: Action) {
    if (action) {
      this.clickAction.emit(action);
    }
  }

  isDisabled(action: Action): boolean {
    return !this.isEnabled && action.type === ActionType.SUCCESS;
  }
}
