/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  Injector,
} from '@angular/core';
import { makeIframeCommunicationService } from '../../../../data/services/common/iframe-communication/iframe-communication-service-factory';
import { ActionIFrame } from '../../../../data/services/common/iframe-communication/iframe-communication-service-impl';
import { InactivityAction } from '../../../../data/services/inactivity/inactivity-service';
import { makeInactivityService } from '../../../../data/services/inactivity/inactivity-service-factory';
import { makeSurveyAnswerSessionService } from '../../../../data/services/survey-answers-session/survey-answers-session-service-factory';
import { LanguageKey } from '../../../../main/translate/language-key/language-key';
import { EventTopic } from '../../../../models/common/event-topic/event-topic';
import { SuperDynamicComponent } from '../../../helpers/super-dynamic-component';
import { Action, ActionType } from '../actions/actions.component';

@Component({
  selector: 'app-timeout',
  templateUrl: './timeout.component.html',
  styleUrls: ['./timeout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeoutComponent
  extends SuperDynamicComponent
  implements AfterContentInit
{
  private inactivityService = makeInactivityService();
  private iframeCommunicationService = makeIframeCommunicationService();
  private surveyAnswerSessionService = makeSurveyAnswerSessionService();

  public message =
    LanguageKey.AT_THE_END_OF_THE_TIME_THIS_SURVEY_WILL_BE_AUTOMATICALLY_TERMINATED;
  public title = LanguageKey.HI_ARE_YOU_THERE;

  private timeoutRef: NodeJS.Timeout;
  public actions: Action[] = [
    { desc: LanguageKey.YES_I_AM_HERE, type: ActionType.SUCCESS },
    {
      desc: LanguageKey.END_SURVEY_AND_GO_BACK_TO_THE_BEGINNING,
      type: ActionType.BASIC,
    },
  ];

  constructor(injector: Injector) {
    super(injector);
  }

  ngAfterContentInit(): void {
    this.timeoutRef = setTimeout(() => this.timeout(), 7e3);
  }

  clearTimeout() {
    clearInterval(this.timeoutRef);
  }

  async continue() {
    switch (this.params.action) {
      case InactivityAction.LEAVE_AND_NOTIFY_PARENT:
        await this.inactivityService.startInactivityTracking(
          InactivityAction.ASK_LEAVE_AND_NOTIFY_PARENT,
          true,
        );
        break;

      case InactivityAction.LEAVE_AND_RESET:
        await this.inactivityService.startInactivityTracking(
          InactivityAction.ASK_LEAVE_AND_RESET,
        );
        break;
    }
    this.clearTimeout();
    this.close(false);
  }

  async timeout() {
    switch (this.params.action) {
      case InactivityAction.LEAVE_AND_NOTIFY_PARENT:
        await this.surveyAnswerSessionService.leaveSession();

        this.iframeCommunicationService.sendToParent({
          action: ActionIFrame.SURVEY_COMPLETED,
        });

        setTimeout(() => {
          this.eventService.emit(EventTopic.INIT_SURVEY);
        }, 5000);

        break;

      case InactivityAction.LEAVE_AND_RESET:
        await this.surveyAnswerSessionService.leaveSession();
        this.eventService.emit(EventTopic.INIT_SURVEY);
        break;
    }
    this.clearTimeout();
    this.close(true);
  }

  async onClickAction(action: Action) {
    if (action.type === ActionType.SUCCESS) {
      await this.continue();
    } else {
      await this.timeout();
    }
  }
}
