import { LocationId } from '../../../main/translate/location-id/location-id';
import { LogLevel } from '../log-level/log-level';

export enum EnvironmentName {
  PROD = 'prod',
  DEV = 'dev',
  TEST = 'test',
}

export class AppEnvironment {
  public ENV_NAME: EnvironmentName;
  public PROJECT_NAME: string;
  public PROJECT_VERSION: string;
  public PROJECT_BUILD_DATE: number;
  public OS_INTERFACE_URL: string;
  public SURVEY_WS: string;
  public SURVEY_CDN: string;
  public LANGUAGE: LocationId;
  public SURVEY_FILE: string;
  public TOKEN: string;
  public LOG_LEVEL: LogLevel = LogLevel.INFO;
  public ALWAYS_DISPLAY_NEXT_BUTTON: boolean;
  public INACTIVITY_TIMEOUT: number;
  public DEFAULT_BANNER_URL: string;
  public INTERVAL_UPDATE_IN_MILLISECONDS: number;
  public DEFAULT_URL_THEME: string;
  public DEFAULT_THEME_NAME: string;

  get isTest(): boolean {
    return this.ENV_NAME === EnvironmentName.TEST;
  }

  get isDev(): boolean {
    return this.ENV_NAME === EnvironmentName.DEV;
  }

  get isProd(): boolean {
    return this.ENV_NAME === EnvironmentName.PROD;
  }

  get buildDate(): string {
    return this.PROJECT_BUILD_DATE.toString();
  }

  get fullVersion() {
    return `Versão: ${this.PROJECT_VERSION} - Build: ${this.buildDate}`;
  }
}
