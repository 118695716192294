import { IAnswerRepository } from './answer-repository';
import { AnswerRepositoryImpl } from './answer-repository-impl';
import { makeIndexedDB } from '../../core/indexed-db-factory';

let answerRepository: IAnswerRepository;

export function makeAnswerRepository() {
  if (!answerRepository) {
    answerRepository = new AnswerRepositoryImpl(makeIndexedDB());
  }
  return answerRepository;
}
