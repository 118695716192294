import { LanguageDict } from '../../common/language-dict/language-dict';
import { Initial } from '../initial/initial';
import { LanguageSurvey } from '../language-survey/language-survey';
import { Question } from '../question/question';

export class Survey {
  id: string;
  title: LanguageDict;
  subtitle: LanguageDict;
  languages: LanguageSurvey[];
  questions: Question[];
  imgLogo: string;
  intervalUpdateInMilliseconds?: number;
  initial: Initial;
  backgroundColor: string;
  textColor: string;
  validity: { start: Date; end: Date };
  final: {
    title: LanguageDict;
    subtitle: LanguageDict;
  };

  firstQuestionId: string;

  public getQuestionById(id: string): Question {
    return this.questions.find((q) => q.id === id);
  }
}
