import { makeIndexedDB } from '../core/indexed-db-factory';
import { ISurveyAnswerSessionRepository } from './survey-answers-session-repository';
import { SurveyAnswerSessionRepositoryImpl } from './survey-answers-session-repository-impl';

let surveyAnswerSessionRepository: ISurveyAnswerSessionRepository;

export function makeSurveyAnswerSessionRepository() {
  if (!surveyAnswerSessionRepository) {
    surveyAnswerSessionRepository = new SurveyAnswerSessionRepositoryImpl(
      makeIndexedDB(),
    );
  }
  return surveyAnswerSessionRepository;
}
