import { makeLogService } from '../../../data/services/common/log/log/log-service-factory';
import { makeGetSurveyIdUseCase } from '../../survey/get-survey-id/get-survey-id-use-case-factory';
import { makeGetSurveyUseCase } from '../../survey/get-survey/get-survey-use-case-factory';
import { CanSelectLanguageUseCase } from './can-select-language-use-case';

export function makeCanSelectLanguageUseCase() {
  return new CanSelectLanguageUseCase(
    makeLogService(),
    makeGetSurveyUseCase(),
    makeGetSurveyIdUseCase(),
  );
}
