import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { interval } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { makeEventService } from '../../../../data/services/common/event/event-service-factory';
import { makeIframeCommunicationService } from '../../../../data/services/common/iframe-communication/iframe-communication-service-factory';
import { ActionIFrame } from '../../../../data/services/common/iframe-communication/iframe-communication-service-impl';
import { makeLoaderService } from '../../../../data/services/common/loader/loader-service-factory';
import { makeLogService } from '../../../../data/services/common/log/log/log-service-factory';
import { InactivityAction } from '../../../../data/services/inactivity/inactivity-service';
import { makeInactivityService } from '../../../../data/services/inactivity/inactivity-service-factory';
import { CustomError } from '../../../../helpers/error/custom-error';
import { LanguageKey } from '../../../../main/translate/language-key/language-key';
import { EventTopic } from '../../../../models/common/event-topic/event-topic';
import { Survey } from '../../../../models/survey/survey/survey';
import { makeSetupUseCase } from '../../../../usecases/setup/setup-use-case-factory';
import { NotFoundComponent } from '../../../components/common/not-found/not-found.component';
import { DynamicComponent } from '../../../helpers/dynamic-component';
import { QuestionComponentsManagerService } from '../../../services/question-components-manager.service';
import { SetupUseCase } from './../../../../usecases/setup/setup-use-case';

@Component({
  selector: 'app-survey',
  templateUrl: './survey-page.component.html',
  styleUrls: ['./survey-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurveyPageComponent implements OnInit, AfterViewInit {
  private survey: Survey;
  private setupUseCase: SetupUseCase = makeSetupUseCase();
  private eventService = makeEventService();
  private iframeCommunicationService = makeIframeCommunicationService();
  private inactivityService = makeInactivityService();
  private alwaysDisplayNextButton = false;
  private logService = makeLogService();
  private loaderService = makeLoaderService();
  private load = null;

  constructor(
    private questionComponenteManagerService: QuestionComponentsManagerService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
  ) {}

  private recoveryOpenedSession = true;

  async ngOnInit(): Promise<void> {
    await this.registerStartAppWhenCallInitSurvey();

    await this.registerShowModalWhenCallUpdate();

    await this.registerStartAppWhenNavigationEnd();
    await this.registerStartAppWhenCallResetOnFinish();
    await this.startWhenNotIframe();
  }

  private async registerStartAppWhenCallInitSurvey(): Promise<void> {
    this.eventService.subscribe(
      EventTopic.INIT_SURVEY,
      async () => {
        await this.startApp(this.recoveryOpenedSession);
      },
      true,
    );
  }

  private async registerStartAppWhenCallResetOnFinish(): Promise<void> {
    this.eventService.subscribe(
      EventTopic.RESET_SURVEY_ON_FINISH,
      async () => {
        await this.startApp(this.recoveryOpenedSession);
      },
      true,
    );
  }

  private async registerShowModalWhenCallUpdate(): Promise<void> {
    this.eventService.subscribe(
      EventTopic.START_UPDATE,
      async () => {
        this.load = this.loaderService.open({
          title: 'Atualizando pesquisa, por favor aguarde...',
        });
      },
      true,
    );

    this.eventService.subscribe(
      EventTopic.STOP_UPDATE,
      async () => {
        this.load.close();
        this.changeDetectorRef.detectChanges();
      },
      true,
    );
  }

  private async startWhenNotIframe(): Promise<void> {
    if (window == window.parent) {
      await this.startApp(true);
      return;
    }
  }

  private async startWhenIframe(): Promise<void> {
    if (window !== window.parent) {
      await this.startApp(false);

      this.eventService.subscribe(
        EventTopic.RESET_SURVEY_ON_FINISH,
        async () => {
          return;
        },
        true,
      );
    }
  }

  private async registerStartAppWhenNavigationEnd(): Promise<void> {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.eventService.emit(EventTopic.INIT_SURVEY);
      }
    });
  }

  async startApp(recoveryOpenedSession?: boolean): Promise<void> {
    await this.logService.debug('SurveyPageComponent -> startApp');
    if (recoveryOpenedSession !== undefined) {
      this.recoveryOpenedSession = recoveryOpenedSession;
    }

    try {
      const { survey } = await this.setupUseCase.handle({
        alwaysDisplayNextButton: this.alwaysDisplayNextButton,
      });

      this.survey = survey;

      if (this.survey) {
        return await this.questionComponenteManagerService.run(this.survey, {
          recoveryOpenedSession: this.recoveryOpenedSession,
        });
      }

      this.changeDetectorRef.detectChanges();
    } catch (error) {
      CustomError.notFound(['Survey not found', error.message]);

      DynamicComponent.openComponent(NotFoundComponent, {
        msg: LanguageKey.SURVEY_NOT_FOUND,
      });
    }
  }

  async nofitySurveyIsLoaded(): Promise<void> {
    await this.logService.debug('SurveyPageComponent -> notifySurveyIsLoaded');
    this.iframeCommunicationService.sendToParent({
      action: ActionIFrame.SURVEY_IS_LOADED,
    });
  }

  async ngAfterViewInit(): Promise<void> {
    await this.logService.debug('SurveyPageComponent -> ngAfterViewInit');
    this.nofitySurveyIsLoaded();
    this.iframeCommunicationService
      .receivesFromParent()
      .pipe(debounce(() => interval(100)))
      .subscribe(async (event: MessageEvent) => {
        if (event.data?.alwaysDisplayNextButton) {
          this.alwaysDisplayNextButton = event.data?.alwaysDisplayNextButton;
        }
        if (event.data?.action === ActionIFrame.ENABLE_TIMEOUT) {
          await this.logService.info(
            'SurveyPageComponent',
            'EnableTimeout from parent',
          );
          await this.inactivityService.startInactivityTracking(
            InactivityAction.ASK_LEAVE_AND_NOTIFY_PARENT,
            true,
          );
        }
        if (event.data?.token) {
          //TODO Salvar e utilizar o TOKEN, e tbm remover do self (URL)
        }
        await this.logService.debug(
          'SurveyPageComponent -> receivesFromParent',
          event,
        );
        // if (window !== window.parent) {
        //   await this.startApp(false);
        // }
        this.startWhenIframe();
      });
  }
}
