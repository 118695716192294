import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  Input,
} from '@angular/core';
import { LanguageDict } from '../../../../models/common/language-dict/language-dict';
import { SuperComponent } from '../../../helpers/super-component';

@Component({
  selector: 'app-header-question',
  templateUrl: './header-question.component.html',
  styleUrls: ['./header-question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderQuestionComponent extends SuperComponent {
  @Input() public title: LanguageDict;

  constructor(injector: Injector) {
    super(injector);
  }
}
