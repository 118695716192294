import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PipesModule } from '../../pipes/pipes.module';
import { ActionsComponent } from './actions/actions.component';
import { LoaderComponent } from './loader/loader.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SelectLanguageComponent } from './select-language/select-language.component';
import { TimeoutComponent } from './timeout/timeout.component';

@NgModule({
  declarations: [
    NotFoundComponent,
    TimeoutComponent,
    ActionsComponent,
    LoaderComponent,
    SelectLanguageComponent,
  ],
  imports: [CommonModule, FormsModule, PipesModule],
  exports: [
    NotFoundComponent,
    TimeoutComponent,
    ActionsComponent,
    LoaderComponent,
    SelectLanguageComponent,
  ],
})
export class CommonComponentsModule {}
