import { Option } from '../../option/option';
import { Answer } from '../answer';

export class AnswerNPS extends Answer {
  public override addOption(option: Option): void {
    if (this.selectedOptions?.length) {
      this.eraseOptions();
    }
    super.addOption(option);
  }
  public override isValid(): boolean {
    if (!super.isValid()) {
      return false;
    }

    const qtSelectedOptions = this.selectedOptions?.length || 0;

    if (
      this.question.required &&
      (qtSelectedOptions == 0 || qtSelectedOptions > 1)
    ) {
      return false;
    }

    return true;
  }
}
