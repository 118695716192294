import { makeLogService } from '../data/services/common/log/log/log-service-factory';
import { makeVolatileLogService } from '../data/services/common/log/volatile-log/volatile-log-service-factory';
import { makeEnvironment } from '../environments/make-environment';

export async function setup() {
  const environment = makeEnvironment();
  const logService = makeLogService();
  const volatileLogSerice = makeVolatileLogService();

  logService.setLogLevel(environment.LOG_LEVEL);
  volatileLogSerice.setLogLevel(environment.LOG_LEVEL);

  window.document.title =
    environment.PROJECT_NAME + ' - ' + environment.fullVersion;
}
