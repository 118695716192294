import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Injector,
  Input,
  Output,
} from '@angular/core';
import { LanguageKey } from '../../../../main/translate/language-key/language-key';
import { SuperComponent } from '../../../helpers/super-component';

@Component({
  selector: 'app-footer-question',
  templateUrl: './footer-question.component.html',
  styleUrls: ['./footer-question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterQuestionComponent extends SuperComponent {
  @Input() public textButton: LanguageKey;
  @Input() public disabled: boolean;
  @Output() public clickAction = new EventEmitter();

  constructor(injector: Injector) {
    super(injector);
  }

  sendResponse() {
    this.clickAction.emit();
  }
}
