import { ILogService } from '../../../data/services/common/log/log-service';
import { IGetSurveyService } from '../../../data/services/get-survey/get-survey-service';
import { CustomError } from '../../../helpers/error/custom-error';
import { Survey } from '../../../models/survey/survey/survey';
import { DownloadSurveyUseCase } from '../download-survey/download-survey-use-case';

export type GetSurveyRequest = { id: string };
export type GetSurveyResponse = Survey;

export class GetSurveyUseCase {
  constructor(
    private logService: ILogService,
    private getSurveyService: IGetSurveyService,
    private downloadSurveyUseCase: DownloadSurveyUseCase,
  ) {}

  async handle(request: GetSurveyRequest): Promise<GetSurveyResponse> {
    try {
      await this.logService.info('GetSurveyUseCase -> start');
      await this.logService.debug('GetSurveyUseCase -> request', request);

      let survey = await this.getSurveyService.getSurvey(request.id);

      if (!survey) {
        await this.logService.info(
          'GetSurveyUseCase -> survey not found trying resync with server',
        );
        await this.downloadSurveyUseCase.handle(request);
        survey = await this.getSurveyService.getSurvey(request.id);
      }

      if (!survey) {
        throw CustomError.notFound(['No survey found in server']);
      }

      await this.logService.debug('GetSurveyUseCase -> response', survey);

      return survey;
    } catch (error) {
      throw CustomError.handleError('GetSurveyUseCase', error);
    } finally {
      await this.logService.info('GetSurveyUseCase -> end');
    }
  }
}
