import pkg from '../../package.json';
import { EnvironmentName } from '../models/common/environment/app-environment';
import { LogLevel } from '../models/common/log-level/log-level';

export const environment = {
  name: EnvironmentName.DEV,
  projectName: pkg.name,
  projectVersion: pkg.version,
  projectBuildDate: Date.now(),
  osInterfaceURL: 'http://localhost:3300',
  surveyWS: 'https://api-answer.survey.homolog.vsd.app',
  surveyCDN: 'https://cdn.survey.homolog.vsd.app',
  defaultBannerUrl:
    'https://cdn.survey.homolog.vsd.app/assets/initial-banner-placeholder.png',
  defaultUrlTheme: 'https://cdn.survey.homolog.vsd.app/assets/themes',
  defaultThemeName: 'default',
  language: 'pt-BR',
  logLevel: LogLevel.DEBUG,
  inactivityTimeOut: 15e3,
  intervalUpdateInMilliseconds: 1800000,
};
