import { ILogService } from '../../data/services/common/log/log-service';
import { IRouterService } from '../../data/services/view/router/router-service';
import { CustomError } from '../../helpers/error/custom-error';
import { Survey } from '../../models/survey/survey/survey';
import { GetSurveyIdUseCase } from '../survey/get-survey-id/get-survey-id-use-case';
import { GetSurveyUseCase } from '../survey/get-survey/get-survey-use-case';
import { StartUpdateUseCase } from '../update-survey/start-update-survey/start-update-use-case';
import { AppEnvironment } from './../../models/common/environment/app-environment';

export type SetupRequest = {
  alwaysDisplayNextButton?: boolean;
};
export type SetupResponse = {
  token: string;
  survey: Survey;
};

export class SetupUseCase {
  constructor(
    private logService: ILogService,
    private routerService: IRouterService,
    private getSurveyUseCase: GetSurveyUseCase,
    private environment: AppEnvironment,
    private startUpdateUseCase: StartUpdateUseCase,
    private getSurveyIdUseCase: GetSurveyIdUseCase,
  ) {}

  async handle(request: SetupRequest): Promise<SetupResponse> {
    try {
      await this.logService.info('SetupUseCase -> start');
      await this.logService.debug('SetupUseCase -> request', request);
      const surveyId = await this.getSurveyIdUseCase.handle();
      const survey = await this.getSurveyUseCase.handle({ id: surveyId });
      await this.startUpdateUseCase.handle(survey);
      const params = {
        survey: survey,
        token: this.routerService.getQueryParam('token'),
        alwaysDisplayNextButton: this.routerService.getQueryParam(
          'alwaysDisplayNextButton',
        ),
      };

      this.environment.TOKEN = params.token;
      this.environment.ALWAYS_DISPLAY_NEXT_BUTTON =
        request.alwaysDisplayNextButton ||
        params.alwaysDisplayNextButton === 'true'
          ? true
          : false;

      return params;
    } catch (error) {
      throw CustomError.handleError('SetupUseCase', error);
    } finally {
      await this.logService.info('SetupUseCase -> end');
    }
  }
}
