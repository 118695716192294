import { LoadOptionDto } from '../../../data/services/download-survey/dto/load-survey-dtos';
import { SurveyMapper } from '../../../data/services/download-survey/dto/survey-mapper';
import { Question } from '../question/question';
import { Answer } from './answer';
import { makeAnswer } from './answer-factory';

export type AnswerDto = {
  id: string;
  questionId: string;
  sessionId: string;
  selectedOptions: LoadOptionDto[];
  skip: boolean;
};

export class AnswerMapper {
  public static toDtoFromModel(answer: Answer): AnswerDto {
    return {
      id: answer.id,
      questionId: answer.question.id,
      selectedOptions: SurveyMapper.fromOptionModel(answer.selectedOptions),
      sessionId: answer.sessionId,
      skip: answer.skip || false,
    } as AnswerDto;
  }

  public static fromDtoToModel(answerDto: AnswerDto, question: Question) {
    return makeAnswer(
      answerDto.sessionId,
      question,
      SurveyMapper.toOptionModel(question.id, answerDto.selectedOptions),
      answerDto.id,
      null,
      answerDto.skip,
    );
  }
}
