import { ILogService } from '../../../data/services/common/log/log-service';
import { IUpdateJobService } from '../../../data/services/update-job-service/update-job-service/update-job-service';
import { CustomError } from '../../../helpers/error/custom-error';

export class StopUpdateSurveyUseCase {
  constructor(
    private logService: ILogService,
    private updateJobService: IUpdateJobService,
  ) {}

  async handle(): Promise<void> {
    try {
      await this.logService.info('StopUpdateUseCase -> start');
      await this.updateJobService.stopJob();
    } catch (error) {
      throw CustomError.handleError('StopUpdateUseCase', error);
    } finally {
      await this.logService.info('StopUpdateUseCase -> end');
    }
  }
}
