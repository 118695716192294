import { Option } from '../../survey/option/option';
import { QuestionType } from '../question-type/question-type';
import { Question } from '../question/question';
import { Answer } from './answer';
import { AnswerChoices } from './choices/answer-choices';
import { AnswerNPS } from './nps/answer-nps';

export function makeAnswer(
  sessionId: string,
  question: Question,
  selectedOptions?: Option[],
  id?: string,
  surveyId?: string,
  skip?: boolean,
) {
  switch (question.type) {
    case QuestionType.NPS:
      return new AnswerNPS(
        sessionId,
        question,
        selectedOptions,
        id,
        surveyId,
        skip,
      );
    case QuestionType.CHOICES:
      return new AnswerChoices(
        sessionId,
        question,
        selectedOptions,
        id,
        surveyId,
        skip,
      );
    default:
      return new Answer(
        sessionId,
        question,
        selectedOptions,
        id,
        surveyId,
        skip,
      );
  }
}
