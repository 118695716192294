/* eslint-disable @typescript-eslint/no-explicit-any */

import { LogLevel } from '../../../../../models/common/log-level/log-level';
import { ILogService } from '../log-service';

export class LogServiceImpl implements ILogService {
  private logLevel: LogLevel;

  async info(message: string, data: any = ''): Promise<void> {
    if (this.logLevel >= LogLevel.INFO) {
      console.info(message, data);
    }
  }

  async error(message: string, data: any = ''): Promise<void> {
    if (this.logLevel >= LogLevel.ERROR) {
      console.error(message, data);
    }
  }

  async warn(message: string, data: any = ''): Promise<void> {
    if (this.logLevel >= LogLevel.WARN) {
      console.warn(message, data);
    }
  }

  async debug(message: string, data: any = ''): Promise<void> {
    if (this.logLevel >= LogLevel.DEBUG) {
      console.debug(message, data);
    }
  }

  async setLogLevel(logLevel: LogLevel): Promise<void> {
    this.logLevel = logLevel;
  }
}
