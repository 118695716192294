import { Pipe, PipeTransform } from '@angular/core';
import { makeTranslateService } from '../../../data/services/common/translate/translate-service-factory';

@Pipe({
  name: 'translateText',
})
export class TranslateTextPipe implements PipeTransform {
  translateService = makeTranslateService();

  transform(text: string): string {
    return this.translateService.translateText(text);
  }
}
