import { IKeyValueRepository } from '../../../data/repositories/key-value/key-value-repository';
import { IEventService } from '../../../data/services/common/event/event-service';
import { ILogService } from '../../../data/services/common/log/log-service';
import { ITranslateSurveyService } from '../../../data/services/common/translate-survey/translate-survey-service';
import { ITranslateService } from '../../../data/services/common/translate/translate-service';
import { CustomError } from '../../../helpers/error/custom-error';
import { LocationId } from '../../../main/translate/location-id/location-id';
import { EventTopic } from '../../../models/common/event-topic/event-topic';
import { RepositoryKey } from '../../../models/common/repository/repository-key';

export type SetCurrentLanguageRequest = LocationId;
export type SetCurrentLanguageResponse = void;

export class SetCurrentLanguageUseCase {
  constructor(
    private logService: ILogService,
    private translateService: ITranslateService,
    private translateSurveyService: ITranslateSurveyService,
    private keyValueRepository: IKeyValueRepository,
    private eventService: IEventService,
  ) {}

  async handle(
    request?: SetCurrentLanguageRequest,
  ): Promise<SetCurrentLanguageResponse> {
    try {
      await this.logService.info('SetCurrentLanguageUseCase -> start');
      await this.logService.debug(
        'SetCurrentLanguageUseCase -> request',
        request,
      );

      const setCurrentLanguage = request
        ? request
        : await this.keyValueRepository.get(RepositoryKey.LOCATION);

      await this.translateService.init(setCurrentLanguage);
      await this.translateSurveyService.init(setCurrentLanguage);

      await this.keyValueRepository.set(
        RepositoryKey.LOCATION,
        setCurrentLanguage,
      );
      this.eventService.emit(EventTopic.LANGUAGE_CHANGED, setCurrentLanguage);
    } catch (error) {
      throw CustomError.handleError('SetCurrentLanguageUseCase', error);
    } finally {
      await this.logService.info('SetCurrentLanguageUseCase -> end');
    }
  }
}
