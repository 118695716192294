import { LoadSurveyDto } from '../../services/download-survey/dto/load-survey-dtos';
import { IIndexedDB } from '../core/indexed-db';
import { DynamicRepositoryImpl } from '../dynamic/dynamic-repository-impl';
import { ISurveyRepository } from './survey-repository';

export class SurveyRepositoryImpl
  extends DynamicRepositoryImpl<LoadSurveyDto>
  implements ISurveyRepository
{
  constructor(private localIndexedDB: IIndexedDB) {
    super(localIndexedDB, 'survey');
  }
}
