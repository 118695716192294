import { IAnswerRepository } from '../../../data/repositories/survey/answer/answer-repository';
import { ILogService } from '../../../data/services/common/log/log-service';
import { CustomError } from '../../../helpers/error/custom-error';
import { Answer } from '../../../models/survey/answer/answer';
import { AnswerMapper } from '../../../models/survey/answer/answer-dto';
import { makeAnswer } from '../../../models/survey/answer/answer-factory';
import { Question } from '../../../models/survey/question/question';

export type NewAnswerRequest = {
  sessionId: string;
  question: Question;
};
export type NewAnswerResponse = { answer: Answer };

export class NewAnswerUseCase {
  constructor(
    private logService: ILogService,
    private answerRepo: IAnswerRepository,
  ) {}

  async handle(request: NewAnswerRequest): Promise<NewAnswerResponse> {
    try {
      await this.logService.info('NewAnswerUseCase -> start');
      await this.logService.debug('NewAnswerUseCase -> request', request);

      if (!request.sessionId) {
        throw CustomError.invalidContent(['Invalid session id']);
      }

      if (!request.question) {
        throw CustomError.invalidContent(['Invalid question']);
      }

      const answer = makeAnswer(request.sessionId, request.question);

      answer.id = await this.answerRepo.save(
        AnswerMapper.toDtoFromModel(answer),
      );

      return { answer: answer };
    } catch (error) {
      throw CustomError.handleError('NewAnswerUseCase', error);
    } finally {
      await this.logService.info('NewAnswerUseCase -> end');
    }
  }
}
