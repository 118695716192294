import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  OnInit,
} from '@angular/core';
import { InactivityAction } from '../../../../data/services/inactivity/inactivity-service';
import { makeInactivityService } from '../../../../data/services/inactivity/inactivity-service-factory';
import { makeEnvironment } from '../../../../environments/make-environment';
import { Answer } from '../../../../models/survey/answer/answer';
import { Option } from '../../../../models/survey/option/option';
import { Question } from '../../../../models/survey/question/question';
import { SurveyAnswerSession } from '../../../../models/survey/survey-answers/survey-answers-session';
import { makeNewAnswerUseCase } from '../../../../usecases/survey/new-answer/new-answer-use-case-factory';
import { makeSaveAnswerUseCase } from '../../../../usecases/survey/save-answer/save-answer-use-case-factory';
import { makeSelectOptionUseCase } from '../../../../usecases/survey/select-option/select-option-use-case-factory';
import { makeSkipQuestionUseCase } from '../../../../usecases/survey/skip-question/skip-question-use-case-factory';
import { makeUnselectOptionUseCase } from '../../../../usecases/survey/unselect-option/unselect-option-use-case-factory';
import { SuperDynamicComponent } from '../../../helpers/super-dynamic-component';
import { AnswerMapper } from './../../../../models/survey/answer/answer-dto';

@Component({
  selector: 'app-question',
  template: '',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionComponent extends SuperDynamicComponent implements OnInit {
  private selectOptionUseCase = makeSelectOptionUseCase();
  private unselectOptionUseCase = makeUnselectOptionUseCase();
  private saveAnswerUseCase = makeSaveAnswerUseCase();
  private skipQuestionUseCase = makeSkipQuestionUseCase();
  private newAnswerUseCase = makeNewAnswerUseCase();
  public environment = makeEnvironment();
  private inactivityService = makeInactivityService();

  public question: Question;
  public answer: Answer;
  public session: SurveyAnswerSession;

  private skip = false;
  private alwaysDisplayNextButton = false;

  constructor(injector: Injector) {
    super(injector);
  }

  async ngOnInit(): Promise<void> {
    this.question = this.params.question;
    this.session = this.params.session;

    await this.loadAnswer();

    this.alwaysDisplayNextButton = this.environment.ALWAYS_DISPLAY_NEXT_BUTTON;

    this.detectChanges();
  }

  async loadAnswer() {
    if (this.session?.answers && this.session?.answers.length > 0) {
      const lastAnswer = this.session?.answers[this.session.answers.length - 1];

      if (lastAnswer.questionId === this.question?.id) {
        this.answer = AnswerMapper.fromDtoToModel(lastAnswer, this.question);
      }
    }
    if (!this.answer) {
      this.answer = (
        await this.newAnswerUseCase.handle({
          sessionId: this.session.id,
          question: this.question,
        })
      ).answer;
    }
  }

  async onCloseQuestion() {
    this.close(this.answer);
  }

  isSkip(): boolean {
    if (this.answer.isEmptyOptions() && !this.question.required) {
      this.skip = true;
    }
    return this.skip;
  }

  async onSave() {
    if (this.isSkip()) {
      return await this.onSkip();
    }
    await this.saveAnswerUseCase.handle(this.answer);
    await this.onCloseQuestion();
  }

  async onSelectOption(option: Option) {
    this.inactivityService.startInactivityTracking(
      this.inactivityService.haveParentRequest
        ? InactivityAction.ASK_LEAVE_AND_NOTIFY_PARENT
        : InactivityAction.ASK_LEAVE_AND_RESET,
    );
    this.answer = await this.selectOptionUseCase.handle({
      answer: this.answer,
      option: option,
    });

    if (!this.showNextQuestion()) {
      await this.onSave();
    }
  }

  async onUnselectOption(option) {
    this.answer = await this.unselectOptionUseCase.handle({
      answer: this.answer,
      option: option,
    });
  }

  async onSkip() {
    this.skip = true;
    this.answer.skip = true;
    await this.skipQuestionUseCase.handle(this.answer);
    await this.onCloseQuestion();
    this.detectChanges();
  }

  showNextQuestion() {
    if (
      this.alwaysDisplayNextButton ||
      !this.question?.required ||
      +this.question?.optionSettings?.choices?.max > 1
    ) {
      return true;
    }

    return false;
  }
}
