export enum LanguageKey {
  AT_THE_END_OF_THE_TIME_THIS_SURVEY_WILL_BE_AUTOMATICALLY_TERMINATED = 'AT_THE_END_OF_THE_TIME_THIS_SURVEY_WILL_BE_AUTOMATICALLY_TERMINATED',
  PORTUGUESE = 'PORTUGUESE',
  END_SURVEY_AND_GO_BACK_TO_THE_BEGINNING = 'END_SURVEY_AND_GO_BACK_TO_THE_BEGINNING',
  ENGLISH = 'ENGLISH',
  SPANISH = 'SPANISH',
  CHANGE_LANGUAGE = 'CHANGE_LANGUAGE',
  CONFIRM = 'CONFIRM',
  HELLO = 'HELLO',
  HI_ARE_YOU_THERE = 'HI_ARE_YOU_THERE',
  DESCRIPTION = 'DESCRIPTION',
  DONE = 'DONE',
  SAVE = 'SAVE',
  EDIT = 'EDIT',
  NOT_FOUND = 'NOT_FOUND',
  SURVEY_NOT_FOUND = 'SURVEY_NOT_FOUND',
  ERROR_OCCURRED = 'ERROR_OCCURRED',
  YES_I_AM_HERE = 'YES_I_AM_HERE',
  NEXT = 'NEXT',
  SEND_REPLY = 'SEND_REPLY',
}
