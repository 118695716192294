import { makeLogService } from '../../data/services/common/log/log/log-service-factory';
import { makeRouterService } from '../../data/services/view/router/router-service-factory';
import { makeEnvironment } from '../../environments/make-environment';
import { makeGetSurveyIdUseCase } from '../survey/get-survey-id/get-survey-id-use-case-factory';
import { makeGetSurveyUseCase } from '../survey/get-survey/get-survey-use-case-factory';
import { makeStartUpdateUseCase } from '../update-survey/start-update-survey/start-update-use-case-factory';
import { SetupUseCase } from './setup-use-case';

export function makeSetupUseCase() {
  return new SetupUseCase(
    makeLogService(),
    makeRouterService(),
    makeGetSurveyUseCase(),
    makeEnvironment(),
    makeStartUpdateUseCase(),
    makeGetSurveyIdUseCase(),
  );
}
