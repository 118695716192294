import { ILogService } from '../../../data/services/common/log/log-service';
import { CustomError } from '../../../helpers/error/custom-error';
import { Survey } from '../../../models/survey/survey/survey';
import { DownloadSurveyUseCase } from '../../survey/download-survey/download-survey-use-case';
import { GetSurveyIdUseCase } from '../../survey/get-survey-id/get-survey-id-use-case';

export class UpdateSurveyUseCase {
  constructor(
    private logService: ILogService,
    private downloadSurveyUseCase: DownloadSurveyUseCase,
    private getSurveyIdUseCase: GetSurveyIdUseCase,
  ) {}

  async handle(): Promise<Survey> {
    try {
      await this.logService.info('UpdateSurveyUseCase -> start');
      const surveyId = await this.getSurveyIdUseCase.handle();
      const survey = await this.downloadSurveyUseCase.handle({ id: surveyId });
      if (!survey) {
        throw CustomError.notFound(['Survey not found']);
      }
      return survey;
    } catch (error) {
      throw CustomError.handleError('UpdateSurveyUseCase', error);
    } finally {
      await this.logService.info('UpdateSurveyUseCase -> end');
    }
  }
}
