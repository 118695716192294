import { CustomError } from '../../../helpers/error/custom-error';

import { Next } from '../next/next';
import { Option } from '../option/option';
import { QuestionType } from '../question-type/question-type';

import { Question } from '../question/question';

export class Answer {
  id: string;
  surveyId: string;
  sessionId: string;
  private _question?: Question;
  private _selectedOptions?: Option[] = [];
  skip = false;

  constructor(
    sessionId: string,
    question: Question,
    selectedOptions?: Option[],
    id?: string,
    surveyId?: string,
    skip?: boolean,
  ) {
    if (id) this.id = id;
    if (question) this._question = question;
    if (selectedOptions) this._selectedOptions = selectedOptions;
    if (sessionId) this.sessionId = sessionId;
    if (surveyId) this.surveyId = surveyId;
    if (skip) this.skip = skip;
  }

  get selectedOptions(): Option[] {
    return this._selectedOptions;
  }

  get question(): Question {
    return this._question;
  }

  set question(value: Question) {
    if (this._question && this._selectedOptions?.length) {
      throw CustomError.invalidContent([
        'Impossible to change the question of an answer with selected options',
      ]);
    }
    this._question = value;
  }

  get nextStep(): Next {
    if (!this.isValid()) {
      throw CustomError.invalidContent(['Answer is not valid']);
    }

    if (this.question.type === QuestionType.NPS) {
      return this.selectedOptions[0].next;
    }

    if (this.question.type === QuestionType.CHOICES) {
      return this.question.next;
    }

    return { id: null, type: 'FINAL' };
  }

  public addOption(option: Option) {
    if (!this.canAddOption(option)) {
      return;
    }
    this._selectedOptions.push(option);
    option.selected = true;
  }

  public isValid(): boolean {
    return true;
  }

  public removeOption(option: Option) {
    if (!this.canRemoveOption()) {
      return;
    }

    this._selectedOptions = this._selectedOptions.filter(
      (item) => item.id !== option.id,
    );

    option.selected = false;
  }

  public eraseOptions() {
    if (!this.canRemoveOption()) {
      return;
    }
    this._selectedOptions = [];
  }

  public isEmptyOptions(): boolean {
    return !this._selectedOptions?.length;
  }

  protected isOptionOfCurrentQuestion(option: Option): boolean {
    return !!this.question.options.find((item) => item.id === option.id);
  }

  protected isFirstOptionSelected(option: Option): boolean {
    return option && !this.selectedOptions.length;
  }

  protected canAddOption(option: Option): boolean {
    return this.isOptionOfCurrentQuestion(option);
  }

  protected canRemoveOption(): boolean {
    return !!this.selectedOptions?.length;
  }
}
