import { makeSurveyAnswerSessionRepository } from '../../../data/repositories/survey-answers-session/survey-answers-session-repository-factory';
import { makeAnswerRepository } from '../../../data/repositories/survey/answer/answer-repository-factory';
import { makeLogService } from '../../../data/services/common/log/log/log-service-factory';
import { makeSurveyAnswerSessionService } from '../../../data/services/survey-answers-session/survey-answers-session-service-factory';
import { SaveAnswerUseCase } from './save-answer-use-case';

export function makeSaveAnswerUseCase() {
  return new SaveAnswerUseCase(
    makeLogService(),
    makeAnswerRepository(),
    makeSurveyAnswerSessionService(),
    makeSurveyAnswerSessionRepository(),
  );
}
