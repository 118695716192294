<div *ngIf="banners?.length">
  <div *ngFor="let banner of banners; index as i">
    <img
      *ngIf="i === currentBanner"
      [src]="banner.url"
      (click)="startSurvey()"
    />
  </div>
</div>

<div *ngIf="!banners?.length">
  <img [src]="environment.DEFAULT_BANNER_URL" (click)="startSurvey()" />
</div>
<app-select-language *ngIf="canSelectLanguage"></app-select-language>
