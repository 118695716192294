<h1>{{ title | translateKey }}</h1>

<div class="loader-border">
  <div class="loader-progress"></div>
</div>

<h2>{{ message | translateKey }}</h2>

<app-actions
  [actions]="actions"
  (clickAction)="onClickAction($event)"
></app-actions>
