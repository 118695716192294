import { makeLogService } from '../../../data/services/common/log/log/log-service-factory';
import { makeDownloadSurveyUseCase } from '../../survey/download-survey/download-survey-use-case-factory';
import { makeGetSurveyIdUseCase } from '../../survey/get-survey-id/get-survey-id-use-case-factory';
import { UpdateSurveyUseCase } from './update-survey-use-case';

export function makeUpdateSurveyUseCase() {
  return new UpdateSurveyUseCase(
    makeLogService(),
    makeDownloadSurveyUseCase(),
    makeGetSurveyIdUseCase(),
  );
}
