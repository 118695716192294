import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TimeGuard } from './guards/time-guard/time.guard';
import { SurveyPageComponent } from './pages/survey/survey/survey-page.component';
import { UpdateSurveyPageComponent } from './pages/update-survey/update-survey/update-survey.component';

const routes: Routes = [
  {
    path: 'survey',
    component: SurveyPageComponent,
  },
  {
    path: 'update',
    component: UpdateSurveyPageComponent,
    canActivate: [TimeGuard],
  },
  {
    path: '',
    redirectTo: '/survey',
    pathMatch: 'prefix',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
