/* eslint-disable @typescript-eslint/no-explicit-any */
import DictionaryJson from '../../../../main/translate/dictionary.json';
import { Dictionary } from '../../../../main/translate/dictionary/dictionary';
import { LanguageKey } from '../../../../main/translate/language-key/language-key';
import {
  Language,
  languages,
} from '../../../../main/translate/language/language';
import { LocationId } from '../../../../main/translate/location-id/location-id';
import { ILogService } from '../log/log-service';
import { ITranslateService } from './translate-service';

export class TranslateServiceImpl implements ITranslateService {
  private language: Language;
  private dictionary: Dictionary;

  constructor(private logService: ILogService) {}

  async init(location: LocationId = 'pt-BR'): Promise<void> {
    if (!this.dictionary || location !== this.language?.id) {
      await this.logService.info('Inicializando tradução para ', location);
      this.language = languages.get(location);
      this.dictionary = DictionaryJson;
    }
  }

  translateKey(key: LanguageKey): string {
    const translated = this.dictionary[key]?.[this.language.id] || key;
    (async () => {
      await this.logService.debug(
        `Traduzindo ${key} para ${this.language.id}. Resultado: ${translated}`,
      );
    })();
    return translated;
  }

  translateText(text: string): string {
    return text;
  }
}
