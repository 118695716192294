import { NgModule } from '@angular/core';
import { TranslateKeyPipe } from './translate-key/translate-key.pipe';
import { TranslateSurveyPipe } from './translate-survey/translate-survey.pipe';
import { TranslateTextPipe } from './translate-text/translate-text.pipe';

@NgModule({
  imports: [],
  declarations: [TranslateKeyPipe, TranslateTextPipe, TranslateSurveyPipe],
  exports: [TranslateKeyPipe, TranslateTextPipe, TranslateSurveyPipe],
})
export class PipesModule {}
