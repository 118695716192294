import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Injector,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import { makeEventService } from '../data/services/common/event/event-service-factory';
import { makeIframeCommunicationService } from '../data/services/common/iframe-communication/iframe-communication-service-factory';
import { ActionIFrame } from '../data/services/common/iframe-communication/iframe-communication-service-impl';
import { makeLogService } from '../data/services/common/log/log/log-service-factory';
import { makeRouterService } from '../data/services/view/router/router-service-factory';
import { EventTopic } from '../models/common/event-topic/event-topic';
import { makeInitLanguageUseCase } from '../usecases/language/init-language/init-language-use-case-factory';
import { CustomInjector } from './helpers/custom-injector';
import { DynamicComponent } from './helpers/dynamic-component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  public eventService = makeEventService();
  private iframeCommunicationService = makeIframeCommunicationService();
  private loggerService = makeLogService();
  private initLanguageUseCase = makeInitLanguageUseCase();
  private routerService = makeRouterService();

  constructor(
    public injector: Injector,
    public viewContainerRef: ViewContainerRef,
  ) {
    this.nofitySurveyIsLoaded();

    DynamicComponent.setViewContainerRef(this.viewContainerRef);
    CustomInjector.setup(this.injector);
  }

  async ngOnInit(): Promise<void> {
    this.routerService.onNavigationEnd(async () => {
      await this.initLanguageUseCase.handle();
    });
  }

  @HostListener('window:mousedown')
  @HostListener('window:keydown')
  monitorUserActivity() {
    this.eventService.emit(EventTopic.USER_ITERACTED_WITH_THE_APP);
  }

  nofitySurveyIsLoaded() {
    this.loggerService.info('Survey -> main -> Notify Survey is loaded');
    this.iframeCommunicationService.sendToParent({
      action: ActionIFrame.SURVEY_IS_LOADED,
    });
  }
}
