import { LanguageDict } from 'src/models/common/language-dict/language-dict';
import { Next } from '../next/next';
import { Option } from '../option/option';
import { QuestionType } from '../question-type/question-type';

export class Question {
  id: string;
  surveyId: string;
  type: QuestionType;
  order: number;
  required: boolean;
  title: LanguageDict;
  subtitle: LanguageDict;
  options: Option[];
  next?: Next;
  optionSettings: OptionSettings;

  findOptionById(id: string): Option {
    return this.options.find((a) => a.id === id);
  }
}

export type OptionSettings = {
  nps?: {
    showOptionScale: boolean | 'false' | 'true';
    startSubLabel: LanguageDict;
    endSubLabel: LanguageDict;
  };
  choices?: {
    min: number;
    max: number;
    next: Next;
  };
};
