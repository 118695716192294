import { makeEnvironment } from '../../../../environments/make-environment';
import { makeSurveyAnswerSessionRepository } from '../../../repositories/survey-answers-session/survey-answers-session-repository-factory';
import { makeAnswerRepository } from '../../../repositories/survey/answer/answer-repository-factory';
import { makeLogService } from '../../common/log/log/log-service-factory';
import { ISynchronizeAnswerService } from './synchronize-answer-service';
import { SynchronizeAnswerServiceImpl } from './synchronize-answer-service-impl';

export function makeSynchronizeAnswerService(): ISynchronizeAnswerService {
  return new SynchronizeAnswerServiceImpl(
    makeLogService(),
    makeSurveyAnswerSessionRepository(),
    makeAnswerRepository(),
    makeEnvironment(),
  );
}
