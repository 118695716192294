/* eslint-disable @typescript-eslint/no-explicit-any */
import { interval } from 'rxjs';
import { makeEnvironment } from '../../../../environments/make-environment';
import { CustomError } from '../../../../helpers/error/custom-error';
import { EventTopic } from '../../../../models/common/event-topic/event-topic';
import { RepositoryKey } from '../../../../models/common/repository/repository-key';
import { Survey } from '../../../../models/survey/survey/survey';
import { UpdateSurveyUseCase } from '../../../../usecases/update-survey/update-survey/update-survey-use-case';
import { IKeyValueRepository } from '../../../repositories/key-value/key-value-repository';
import { IEventService } from '../../common/event/event-service';
import { ILogService } from '../../common/log/log-service';
import { IUpdateJobService } from './update-job-service';

export class UpdateJobServiceImpl implements IUpdateJobService {
  private subscription: any;
  private environment = makeEnvironment();
  private survey: Survey;
  private updateInProgress = false;

  constructor(
    private logService: ILogService,
    private updateSurveyUseCase: UpdateSurveyUseCase,
    private keyValueRepository: IKeyValueRepository,
    private eventService: IEventService,
  ) {}

  async setSurvey(survey: Survey): Promise<void> {
    await this.logService.info('UpdateJobServiceImpl -> setSurvey');
    if (!survey) {
      return;
    }
    this.survey = survey;
  }

  async startJob(): Promise<void> {
    try {
      await this.logService.info('UpdateJobServiceImpl -> startJob');
      if (this.updateInProgress) {
        await this.logService.info(
          'UpdateJobServiceImpl -> startJob -> updateInProgress',
        );
        return;
      }
      if (!this.survey) {
        throw CustomError.handleError(
          'UpdateJobServiceImpl',
          'Survey not found',
        );
      }

      this.updateInProgress = true;
      const intervalTimeToUpdate = await this.getIntervalTimeToUpdate();
      await this.logService.info(
        'UpdateJobServiceImpl -> await time update ',
        intervalTimeToUpdate,
      );
      const observable = interval(intervalTimeToUpdate);
      this.newTimeToUpdate();
      this.subscription = observable.subscribe(async () => {
        this.stopJob();
        await this.update();
        this.startJob();
      });
    } catch (error) {
      throw CustomError.handleError('UpdateSurveyUseCase', error);
    }
  }

  async stopJob(): Promise<void> {
    await this.logService.info('UpdateJobServiceImpl -> stopJob');
    if (this.subscription) {
      this.updateInProgress = false;
      if (!this.subscription?.closed) {
        this.subscription.unsubscribe();
      }
    }
  }

  private async update(): Promise<void> {
    await this.logService.info('UpdateJobServiceImpl -> update');
    this.eventService.emit(EventTopic.START_UPDATE);
    this.survey = await this.updateSurveyUseCase.handle();
    await this.informeNewUpdateTime();
    this.eventService.emit(EventTopic.STOP_UPDATE);
  }

  private async informeNewUpdateTime(): Promise<Date> {
    const newDate = await this.newTimeToUpdate();
    this.keyValueRepository.set(
      RepositoryKey.LAST_SURVEY_UPDATE,
      newDate.getTime(),
    );
    return newDate;
  }

  private async newTimeToUpdate(): Promise<Date> {
    const now = new Date();
    return new Date(
      now.getTime() + this.environment.INTERVAL_UPDATE_IN_MILLISECONDS,
    );
  }

  private async getIntervalTimeToUpdate(): Promise<number> {
    const updateDate =
      (await this.keyValueRepository.get(RepositoryKey.LAST_SURVEY_UPDATE)) ||
      0;
    const now = new Date();

    if (updateDate && updateDate > now.getTime()) {
      return updateDate - now.getTime();
    } else {
      return 200;
    }
  }
}
