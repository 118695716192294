import { ILogService } from '../../../data/services/common/log/log-service';
import { ISynchronizeAnswerService } from '../../../data/services/net/synchronize-answer/synchronize-answer-service';
import { ISurveyAnswerSessionService } from '../../../data/services/survey-answers-session/survey-answers-session-service';
import { CustomError } from '../../../helpers/error/custom-error';

export type FinishSurveySessionResponse = void;

export class FinishSurveySessionUseCase {
  constructor(
    private logService: ILogService,
    private surveyAnswerSessionService: ISurveyAnswerSessionService,
    private syncAnswerService: ISynchronizeAnswerService,
  ) {}

  async handle(): Promise<FinishSurveySessionResponse> {
    try {
      await this.logService.info('FinishSurveySessionUseCase -> start');

      await this.surveyAnswerSessionService.endSession();

      await this.syncAnswerService.synchronizeAnswer();
    } catch (error) {
      throw CustomError.handleError('FinishSurveySessionUseCase', error);
    } finally {
      await this.logService.info('FinishSurveySessionUseCase -> end');
    }
  }
}
