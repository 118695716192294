/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentRef, Injectable } from '@angular/core';
import { makeEventService } from '../../data/services/common/event/event-service-factory';
import { makeIframeCommunicationService } from '../../data/services/common/iframe-communication/iframe-communication-service-factory';
import { makeLogService } from '../../data/services/common/log/log/log-service-factory';
import { InactivityAction } from '../../data/services/inactivity/inactivity-service';
import { makeInactivityService } from '../../data/services/inactivity/inactivity-service-factory';
import { makeSurveyAnswerSessionService } from '../../data/services/survey-answers-session/survey-answers-session-service-factory';
import { CustomError } from '../../helpers/error/custom-error';
import { EventTopic } from '../../models/common/event-topic/event-topic';
import { Answer } from '../../models/survey/answer/answer';
import { Banner } from '../../models/survey/banner/banner';
import { InitialType } from '../../models/survey/initial-type/initial-type';
import { LanguageSurvey } from '../../models/survey/language-survey/language-survey';
import { Next } from '../../models/survey/next/next';
import { QuestionType } from '../../models/survey/question-type/question-type';
import { Question } from '../../models/survey/question/question';
import { SurveyAnswerSession } from '../../models/survey/survey-answers/survey-answers-session';
import { Survey } from '../../models/survey/survey/survey';
import { FinishComponent } from '../components/survey/finish/finish.component';
import { QuestionNpsComponent } from '../components/survey/question-nps/question-nps.component';
import { QuestionOptionsChoiseComponent } from '../components/survey/question-options-choise/question-options-choise.component';
import { BannerComponent } from '../components/survey/start/banner/banner.component';
import { DynamicComponent } from '../helpers/dynamic-component';
import { ActionIFrame } from './../../data/services/common/iframe-communication/iframe-communication-service-impl';

@Injectable({
  providedIn: 'root',
})
export class QuestionComponentsManagerService {
  private logService = makeLogService();
  private surveyAnswerSessionService = makeSurveyAnswerSessionService();
  private eventService = makeEventService();
  private iframeCommunicationService = makeIframeCommunicationService();
  private inactivityService = makeInactivityService();

  private survey: Survey;
  private currentQuestionId: string;
  private session: SurveyAnswerSession;
  public languages: LanguageSurvey[];

  async run(survey: Survey, params: { recoveryOpenedSession?: boolean }) {
    this.survey = survey;
    this.languages = survey.languages;
    await this.startSession(params.recoveryOpenedSession);

    await this.manageTimeout();
    await this.checkLastAnswerAndStartQuestion();
  }

  private async manageTimeout() {
    if (
      !this.inactivityService.haveParentRequest &&
      !this.session.lastQuestionId &&
      !this.survey?.initial?.type
    ) {
      // está num totem individual e não foi respondido então espera
      await this.inactivityService.stopInactivityTracking();
    } else if (
      !this.inactivityService.haveParentRequest &&
      !this.session.lastQuestionId &&
      this.survey?.initial?.type
    ) {
      await this.inactivityService.startInactivityTracking(
        InactivityAction.ASK_LEAVE_AND_RESET,
        false,
      );
    } else if (
      !this.session.lastQuestionId &&
      this.inactivityService.haveParentRequest
    ) {
      // está dentro de uma janela e não foi respondido então abandona e avisa o pai
      await this.inactivityService.startInactivityTracking(
        InactivityAction.ASK_LEAVE_AND_NOTIFY_PARENT,
        true,
      );
    } else if (this.session.lastQuestionId) {
      // já tem alguma resposta, então pede para o usuário confirmar se quer continuar
      // caso não queira e esteja dentro de uma janela, abandona e avisa o pai
      // caso não queira e esteja num totem individual, abandona e não avisa e reseta
      await this.inactivityService.startInactivityTracking(
        this.inactivityService.haveParentRequest
          ? InactivityAction.ASK_LEAVE_AND_NOTIFY_PARENT
          : InactivityAction.ASK_LEAVE_AND_RESET,
      );
    }
  }

  async checkLastAnswerAndStartQuestion() {
    const lastAnswer = this.session?.answers
      ? this.session.answers[this.session.answers.length - 1]
      : null;

    if (lastAnswer) {
      await this.bindQuestionComponent(
        this.survey.getQuestionById(lastAnswer.questionId),
      );
      return;
    }
    // tem start então show start banner and return
    if (this.survey?.initial?.type) {
      await this.showInitialStep();
      return;
    }

    await this.bindNextStep({
      type: 'QUESTION',
      id: this.survey.firstQuestionId,
    });
  }

  async startSession(recoveryOpenedSession = true): Promise<void> {
    if (recoveryOpenedSession) {
      this.logService.debug(
        'QuestionComponentsManagerService -> startSession -> recoveryOpenedSession',
      );
      this.session = await this.surveyAnswerSessionService.getSession(
        this.survey.id,
      );
    } else {
      this.logService.debug(
        'QuestionComponentsManagerService -> startSession -> createSession',
      );
      this.session = await this.surveyAnswerSessionService.createSession(
        this.survey.id,
      );
    }

    if (!this.session) {
      throw CustomError.notFound(['Session not found']);
    }

    if (!this.session.lastQuestionId && recoveryOpenedSession) {
      await this.surveyAnswerSessionService.updateInitDateSession();
    }
  }

  private async showNextQuestion(nextQuestionId?: string) {
    await this.logService.info(
      'QuestionComponentsManagerService -> showNextQuestion',
    );
    this.currentQuestionId = nextQuestionId;

    await this.bindQuestionComponent(
      this.survey.getQuestionById(nextQuestionId),
    );
  }

  private async bindNextStep(next: Next) {
    if (next.type === 'QUESTION') this.showNextQuestion(next.id);
    else if (next.type === 'FINAL') this.showFinalStep();
  }

  private async bindQuestionComponent(question: Question) {
    switch (question.type) {
      case QuestionType.NPS:
        await this.showNpsQuestion(question);
        break;
      case QuestionType.CHOICES:
        await this.showChoicesQuestion(question);
        break;
      case QuestionType.TEXT:
        await this.showTextQuestion();
        break;
      default:
        await this.logService.error(
          'QuestionComponentsManagerService -> bindQuestionComponent -> question type not found',
          question.type,
        );
        throw CustomError.notFound(['Question type not found']);
    }
  }

  private async showNpsQuestion(question: Question) {
    await this.logService.info(
      'QuestionComponentsManagerService -> showNpsQuestion',
    );

    const dynamicComponent = DynamicComponent.openComponent(
      QuestionNpsComponent,
      {
        question: question,
        session: this.session,
      },
      true,
    );
    const componentRef = dynamicComponent.componentRef;
    dynamicComponent.onClose((result) => {
      this.closeQuestionCallback(result);
    });
    this.setComponentRef(componentRef);
  }

  private async showChoicesQuestion(question: Question) {
    await this.logService.info(
      'QuestionComponentsManagerService -> showChoicesQuestion',
    );

    const dynamicComponent = DynamicComponent.openComponent(
      QuestionOptionsChoiseComponent,
      {
        question: question,
        session: this.session,
      },
      true,
    );
    const componentRef = dynamicComponent.componentRef;
    dynamicComponent.onClose((result) => {
      this.closeQuestionCallback(result);
    });
    this.setComponentRef(componentRef);
  }

  private setComponentRef(componentRef: ComponentRef<any>) {
    this.inactivityService.componentRef = componentRef;
  }

  private async showTextQuestion() {
    await this.logService.info(
      'QuestionComponentsManagerService -> showTextQuestion',
    );
  }

  private async showFinalStep() {
    await this.logService.info(
      'QuestionComponentsManagerService -> showFinalStep',
    );

    DynamicComponent.openComponent(
      FinishComponent,
      {
        title: this.survey.final?.title,
        subtitle: this.survey.final?.subtitle,
      },
      true,
    ).onClose(() => {
      this.iframeCommunicationService.sendToParent({
        action: ActionIFrame.SURVEY_COMPLETED,
      });
      this.eventService.emit(EventTopic.RESET_SURVEY_ON_FINISH);
    });
  }

  private async showInitialStep() {
    await this.logService.info(
      'QuestionComponentsManagerService -> showInitialStep',
    );

    if (!this.inactivityService.haveParentRequest) {
      await this.inactivityService.stopInactivityTracking();
    }
    switch (this.survey?.initial?.type) {
      case InitialType.BANNER:
        await this.showBanner(this.survey?.initial?.banners);
        break;

      default:
        await this.logService.error(
          'QuestionComponentsManagerService -> showInitialStep -> showBanner type not found',
          this.survey?.initial?.type,
        );
        throw CustomError.notFound(['showBanner type not found']);
    }
  }

  private async showBanner(banners: Banner[]): Promise<void> {
    await this.logService.info(
      'QuestionComponentsManagerService -> showBanner',
    );
    DynamicComponent.openComponent(BannerComponent, {
      banners: banners,
    }).onClose(async () => {
      (this.session.initDate = new Date()), await this.manageTimeout();
      await this.bindNextStep({
        type: 'QUESTION',
        id: this.survey.firstQuestionId,
      });
    });
  }

  private closeQuestionCallback = (answer: Answer) => {
    if (answer?.nextStep) {
      this.bindNextStep(answer.nextStep);
    }
  };
}
