import { ILogService } from '../../../data/services/common/log/log-service';
import { IUpdateJobService } from '../../../data/services/update-job-service/update-job-service/update-job-service';
import { CustomError } from '../../../helpers/error/custom-error';
import { Survey } from '../../../models/survey/survey/survey';

export class StartUpdateUseCase {
  constructor(
    private logService: ILogService,
    private updateJobService: IUpdateJobService,
  ) {}

  async handle(survey?: Survey): Promise<void> {
    try {
      await this.logService.info('StartUpdateUseCase -> start');
      await this.updateJobService.setSurvey(survey);
      await this.updateJobService.startJob();
    } catch (error) {
      throw CustomError.handleError('StartUpdateUseCase', error);
    } finally {
      await this.logService.info('StartUpdateUseCase -> end');
    }
  }
}
