import { LocationId } from '../main/translate/location-id/location-id';
import { AppEnvironment } from '../models/common/environment/app-environment';
import { LogLevel } from '../models/common/log-level/log-level';
import { environment } from './environment';

let appEnvironment: AppEnvironment;

export function makeEnvironment() {
  if (!appEnvironment) {
    appEnvironment = new AppEnvironment();
    appEnvironment.ENV_NAME = environment.name;
    appEnvironment.PROJECT_NAME = environment.projectName;
    appEnvironment.PROJECT_VERSION = environment.projectVersion;
    appEnvironment.PROJECT_BUILD_DATE = Number(environment.projectBuildDate);
    appEnvironment.LANGUAGE = environment.language as LocationId;
    appEnvironment.SURVEY_WS = environment.surveyWS;
    appEnvironment.SURVEY_CDN = environment.surveyCDN;
    appEnvironment.DEFAULT_BANNER_URL = environment.defaultBannerUrl;
    appEnvironment.INTERVAL_UPDATE_IN_MILLISECONDS =
      environment.intervalUpdateInMilliseconds;

    if (environment?.logLevel && environment.logLevel in LogLevel) {
      appEnvironment.LOG_LEVEL = environment.logLevel;
    }
    appEnvironment.INACTIVITY_TIMEOUT = environment.inactivityTimeOut;
    appEnvironment.DEFAULT_URL_THEME = environment.defaultUrlTheme;
    appEnvironment.DEFAULT_THEME_NAME = environment.defaultThemeName;
  }
  return appEnvironment;
}

const env = makeEnvironment();
if (env.ENV_NAME !== 'test') {
  console.log('environment: \n', env);
}
