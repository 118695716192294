import { makeUpdateSurveyUseCase } from '../../../../usecases/update-survey/update-survey/update-survey-use-case-factory';
import { makeKeyValueRepository } from '../../../repositories/key-value/key-value-repository-factory';
import { makeEventService } from '../../common/event/event-service-factory';
import { makeLogService } from '../../common/log/log/log-service-factory';
import { IUpdateJobService } from './update-job-service';
import { UpdateJobServiceImpl } from './update-job-service-impl';

let updateJobService: IUpdateJobService;

export function makeUpdateJobService() {
  if (!updateJobService) {
    updateJobService = new UpdateJobServiceImpl(
      makeLogService(),
      makeUpdateSurveyUseCase(),
      makeKeyValueRepository(),
      makeEventService(),
    );
  }
  return updateJobService;
}
