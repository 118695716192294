import { ILogService } from '../../../data/services/common/log/log-service';
import { IDownloadSurveyService } from '../../../data/services/download-survey/download-survey-service';
import { CustomError } from '../../../helpers/error/custom-error';
import { Survey } from '../../../models/survey/survey/survey';

export type DownloadSurveyRequest = { id: string };
export type DownloadSurveyResponse = Survey | false;

export class DownloadSurveyUseCase {
  constructor(
    private logService: ILogService,
    private downloadSurveyService: IDownloadSurveyService,
  ) {}

  async handle(
    request: DownloadSurveyRequest,
  ): Promise<DownloadSurveyResponse> {
    try {
      await this.logService.info('DownloadSurveyUseCase -> start');
      await this.logService.debug('DownloadSurveyUseCase -> request', request);
      const survey = await this.downloadSurveyService.load(request.id);

      if (!survey) {
        CustomError.notFound(['DownloadSurveyUseCase -> survey not found']);
      }

      return survey as Survey;
    } catch (error) {
      throw CustomError.handleError('DownloadSurveyUseCase -> error', error);
    } finally {
      await this.logService.info('DownloadSurveyUseCase -> end');
    }
  }
}
