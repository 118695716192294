import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  OnInit,
} from '@angular/core';
import { Option } from '../../../../models/survey/option/option';
import { makeCanSelectLanguageUseCase } from '../../../../usecases/language/can-select-language/can-select-language-use-case-factory';
import { QuestionComponent } from '../question/question.component';

@Component({
  selector: 'app-question-nps',
  templateUrl: './question-nps.component.html',
  styleUrls: ['./question-nps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionNpsComponent extends QuestionComponent implements OnInit {
  private canSelectLanguageUseCase = makeCanSelectLanguageUseCase();

  public canSelectLanguage = false;
  public list: Option[] = [];

  constructor(injector: Injector) {
    super(injector);
  }

  override async ngOnInit(): Promise<void> {
    await super.ngOnInit();
    this.canSelectLanguage = await this.canSelectLanguageUseCase.handle();

    for (const opt of this.question.options) {
      this.answer?.selectedOptions.map(async (optSelected) => {
        if (opt.id === optSelected.id) {
          opt.selected = true;
          return;
        }
      });
    }

    this.question.options.forEach((option) => {
      this.list.push(option);
    });

    this.detectChanges();
  }

  async selectOption(option: Option) {
    const selectedOption = this.list.filter((item) => item.selected);
    if (selectedOption.length > 0) {
      await super.onUnselectOption(selectedOption[0]);
    }

    if (selectedOption.length > 0 && selectedOption[0].id === option.id) {
      return;
    }
    await super.onSelectOption(option);
    this.detectChanges();
  }

  async sendResponse() {
    await this.onSave();
  }

  override close() {
    super.close(this.answer);
  }

  public backgroundColor(option: Option) {
    const showOptionScale = this.question?.optionSettings?.nps?.showOptionScale;
    const BGColorSelected = option.backgroundColorSelected;
    const BGColorUnselected = option.backgroundColorUnselected;

    if (option.selected) {
      return showOptionScale ? BGColorUnselected : BGColorSelected;
    } else {
      return showOptionScale ? BGColorSelected : BGColorUnselected;
    }
  }

  public color(option: Option) {
    const showOptionScale = this.question?.optionSettings?.nps?.showOptionScale;
    const textColorSelected = option.textColorSelected;
    const textColorUnselected = option.textColorUnselected;

    if (option.selected) {
      return showOptionScale ? textColorSelected : textColorUnselected;
    } else {
      return showOptionScale ? textColorUnselected : textColorSelected;
    }
  }

  border(option) {
    const showOptionScale = this.question?.optionSettings?.nps?.showOptionScale;
    const colorBg = option.backgroundColorSelected;
    if (option.selected && showOptionScale) {
      return showOptionScale ? 'solid ' + colorBg : '';
    }

    return '';
  }

  public boxShadow() {
    const showOptionScale = this.question?.optionSettings?.nps?.showOptionScale;
    return showOptionScale ? 'none' : '';
  }
}
