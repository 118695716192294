import { ILogService } from '../../../data/services/common/log/log-service';
import { IRouterService } from '../../../data/services/view/router/router-service';
import { CustomError } from '../../../helpers/error/custom-error';

export type GetSurveyIdResponse = string;

export class GetSurveyIdUseCase {
  constructor(
    private logService: ILogService,
    private routerService: IRouterService,
  ) {}

  async handle(): Promise<GetSurveyIdResponse> {
    try {
      await this.logService.info('GetSurveyIdUseCase -> start');
      const surveyId = this.routerService.getQueryParam('surveyId');
      return surveyId;
    } catch (error) {
      throw CustomError.handleError('GetSurveyIdUseCase', error);
    } finally {
      await this.logService.info('GetSurveyIdUseCase -> end');
    }
  }
}
