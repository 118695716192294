import { Component, OnInit } from '@angular/core';
import { makeEnvironment } from '../../../../../environments/make-environment';
import { Banner } from '../../../../../models/survey/banner/banner';
import { makeCanSelectLanguageUseCase } from '../../../../../usecases/language/can-select-language/can-select-language-use-case-factory';
import { StartComponent } from '../start.component';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent extends StartComponent implements OnInit {
  public environment = makeEnvironment();
  private canSelectLanguageUseCase = makeCanSelectLanguageUseCase();

  public canSelectLanguage = false;
  public banners: Banner[];
  public url: string;
  public timeoutRef: ReturnType<typeof setTimeout>;
  public currentBanner = 0;

  override async ngOnInit(): Promise<void> {
    this.canSelectLanguage = await this.canSelectLanguageUseCase.handle();
    this.banners = this.params.banners;

    if (this.banners.length > 1) {
      await this.startBannerTransition();
    }
    this.detectChanges();
  }

  async startBannerTransition(): Promise<void> {
    this.timeoutRef = setTimeout(() => {
      this.currentBanner = (1 + this.currentBanner) % this.banners.length;

      this.startBannerTransition();

      this.detectChanges();
    }, Number(this.banners[this.currentBanner].timeout));
  }

  startSurvey() {
    super.close();
  }
}
