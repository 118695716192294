import { makeSurveyRepository } from '../../repositories/survey/survey-repository-factory';
import { makeLogService } from '../common/log/log/log-service-factory';
import { IGetSurveyService } from './get-survey-service';
import { GetSurveyServiceImpl } from './get-survey-service-impl';

let getSurveyService: IGetSurveyService;

export function makeGetSurveyService() {
  if (!getSurveyService) {
    getSurveyService = new GetSurveyServiceImpl(
      makeLogService(),
      makeSurveyRepository(),
    );
  }
  return getSurveyService;
}
