import { makeLogService } from '../../../data/services/common/log/log/log-service-factory';
import { makeDownloadSurveyService } from '../../../data/services/download-survey/download-survey-service-factory';
import { DownloadSurveyUseCase } from './download-survey-use-case';

export function makeDownloadSurveyUseCase() {
  const logService = makeLogService();
  const loadSurveyService = makeDownloadSurveyService();

  return new DownloadSurveyUseCase(logService, loadSurveyService);
}
