import { AnswerDto } from '../../../../models/survey/answer/answer-dto';
import { IIndexedDB } from '../../core/indexed-db';

import { DynamicRepositoryImpl } from '../../dynamic/dynamic-repository-impl';
import { IAnswerRepository } from './answer-repository';

export class AnswerRepositoryImpl
  extends DynamicRepositoryImpl<AnswerDto>
  implements IAnswerRepository
{
  constructor(private localIndexedDB: IIndexedDB) {
    super(localIndexedDB, 'answer');
  }

  getAnswerBySession(sessionId: string): Promise<AnswerDto[]> {
    return this.table.where('sessionId').equals(sessionId).toArray();
  }

  async deleteListBySessionId(sessionIds: string[]): Promise<void> {
    this.table.filter((item) => sessionIds.includes(item.sessionId)).delete();
  }
}
