import { ILogService } from '../../../data/services/common/log/log-service';
import { CustomError } from '../../../helpers/error/custom-error';
import { GetSurveyIdUseCase } from '../../survey/get-survey-id/get-survey-id-use-case';
import { GetSurveyUseCase } from '../../survey/get-survey/get-survey-use-case';

export type CanSelectLanguageResponse = boolean;

export class CanSelectLanguageUseCase {
  constructor(
    private logService: ILogService,
    private getSurveyUseCase: GetSurveyUseCase,
    private getSurveyIdUseCase: GetSurveyIdUseCase,
  ) {}

  async handle(): Promise<CanSelectLanguageResponse> {
    try {
      await this.logService.info('CanSelectLanguageUseCase -> start');
      const surveyId = await this.getSurveyIdUseCase.handle();
      const survey = await this.getSurveyUseCase.handle({ id: surveyId });

      if (survey.languages.length > 1) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      throw CustomError.handleError('CanSelectLanguageUseCase', error);
    } finally {
      await this.logService.info('CanSelectLanguageUseCase -> end');
    }
  }
}
