import { IAnswerRepository } from '../../../data/repositories/survey/answer/answer-repository';
import { ILogService } from '../../../data/services/common/log/log-service';
import { CustomError } from '../../../helpers/error/custom-error';
import { Answer } from '../../../models/survey/answer/answer';
import { AnswerMapper } from '../../../models/survey/answer/answer-dto';
import { Option } from '../../../models/survey/option/option';

export type SelectOptionRequest = { answer: Answer; option: Option };
export type SelectOptionResponse = Answer;

export class SelectOptionUseCase {
  constructor(
    private logService: ILogService,
    private answerRepo: IAnswerRepository,
  ) {}

  async handle(request: SelectOptionRequest): Promise<SelectOptionResponse> {
    try {
      await this.logService.info('SelectOptionUseCase -> start');
      await this.logService.debug('SelectOptionUseCase -> request', request);

      request.answer.addOption(request.option);

      request.answer.id = await this.answerRepo.save(
        AnswerMapper.toDtoFromModel(request.answer),
      );

      return request.answer;
    } catch (error) {
      throw CustomError.handleError('SelectOptionUseCase', error);
    } finally {
      await this.logService.info('SelectOptionUseCase -> end');
    }
  }
}
