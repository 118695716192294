import { makeStopUpdateSurveyUseCase } from 'src/usecases/update-survey/stop-update-survey/stop-update-survey-use-case-factory';
import { makeStartUpdateUseCase } from '../../../usecases/update-survey/start-update-survey/start-update-use-case-factory';
import { makeEventService } from '../common/event/event-service-factory';
import { makeLogService } from '../common/log/log/log-service-factory';
import { IInactivityService } from './inactivity-service';
import { InactivityServiceImpl } from './inactivity-service-impl';

let inactivityService: IInactivityService = null;
export function makeInactivityService(): IInactivityService {
  if (!inactivityService) {
    inactivityService = new InactivityServiceImpl(
      makeLogService(),
      makeEventService(),
      makeStartUpdateUseCase(),
      makeStopUpdateSurveyUseCase(),
    );
  }
  return inactivityService;
}
