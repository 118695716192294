/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  OnInit,
} from '@angular/core';
import { makeRouterService } from '../../../../data/services/view/router/router-service-factory';
import { makeEnvironment } from '../../../../environments/make-environment';
import { LocationId } from '../../../../main/translate/location-id/location-id';
import { EventTopic } from '../../../../models/common/event-topic/event-topic';
import { LanguageSurvey } from '../../../../models/survey/language-survey/language-survey';
import { makeSetCurrentLanguageUseCase } from '../../../../usecases/language/set-current-language/set-current-language-use-case-factory';
import { makeGetSurveyIdUseCase } from '../../../../usecases/survey/get-survey-id/get-survey-id-use-case-factory';
import { makeGetSurveyUseCase } from '../../../../usecases/survey/get-survey/get-survey-use-case-factory';
import { SuperComponent } from '../../../helpers/super-component';
import { QuestionComponentsManagerService } from '../../../services/question-components-manager.service';

@Component({
  selector: 'app-select-language',
  templateUrl: './select-language.component.html',
  styleUrls: ['./select-language.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectLanguageComponent extends SuperComponent implements OnInit {
  private getSurveyUseCase = makeGetSurveyUseCase();
  public environment = makeEnvironment();
  public routerService = makeRouterService();
  public setCurrentLanguageUseCase = makeSetCurrentLanguageUseCase();
  public getSurveyIdUseCase = makeGetSurveyIdUseCase();
  public urlImg = `${this.environment.DEFAULT_URL_THEME}/${this.environment.DEFAULT_THEME_NAME}/`;
  public currentLocation: LocationId;
  public languages: LanguageSurvey[];

  constructor(
    public injector: Injector,
    private questionComponentsManagerService: QuestionComponentsManagerService,
  ) {
    super(injector);
  }

  async ngOnInit(): Promise<void> {
    this.languages = this.questionComponentsManagerService.languages;

    this.subscribe(EventTopic.LANGUAGE_CHANGED, (location: LocationId) => {
      this.currentLocation = location;
      this.detectChanges();
    });

    await this.setCurrentLanguageUseCase.handle();
    this.detectChanges();
  }

  public async changeLanguage(location: LocationId): Promise<void> {
    await this.setCurrentLanguageUseCase.handle(location);
  }

  public classLanguage(language: LanguageSurvey): string {
    if (language?.name === this.currentLocation) {
      return 'selected-location';
    }
    return '';
  }
}
