import { makeAnswerRepository } from 'src/data/repositories/survey/answer/answer-repository-factory';
import { makeSurveyAnswerSessionRepository } from '../../repositories/survey-answers-session/survey-answers-session-repository-factory';
import { makeLogService } from '../common/log/log/log-service-factory';
import { ISurveyAnswerSessionService } from './survey-answers-session-service';
import { SurveyAnswerSessionServiceImpl } from './survey-answers-session-service-impl';

let surveyAnswerSessionService: ISurveyAnswerSessionService;

export function makeSurveyAnswerSessionService() {
  if (!surveyAnswerSessionService) {
    surveyAnswerSessionService = new SurveyAnswerSessionServiceImpl(
      makeLogService(),
      makeSurveyAnswerSessionRepository(),
      makeAnswerRepository(),
    );
  }
  return surveyAnswerSessionService;
}
