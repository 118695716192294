import { makeLogService } from '../../../data/services/common/log/log/log-service-factory';
import { makeGetSurveyService } from '../../../data/services/get-survey/get-survey-service-factory';
import { makeDownloadSurveyUseCase } from '../download-survey/download-survey-use-case-factory';
import { GetSurveyUseCase } from './get-survey-use-case';

export function makeGetSurveyUseCase() {
  return new GetSurveyUseCase(
    makeLogService(),
    makeGetSurveyService(),
    makeDownloadSurveyUseCase(),
  );
}
