import { ILogService } from '../../../data/services/common/log/log-service';
import { IRouterService } from '../../../data/services/view/router/router-service';
import { CustomError } from '../../../helpers/error/custom-error';
import { LocationId } from '../../../main/translate/location-id/location-id';
import { AppEnvironment } from '../../../models/common/environment/app-environment';
import { GetSurveyIdUseCase } from '../../survey/get-survey-id/get-survey-id-use-case';
import { GetSurveyUseCase } from '../../survey/get-survey/get-survey-use-case';
import { SetCurrentLanguageUseCase } from '../set-current-language/set-current-language-use-case';

export type InitLanguageResponse = void;

export class InitLanguageUseCase {
  constructor(
    private logService: ILogService,
    private setCurrentLanguageUseCase: SetCurrentLanguageUseCase,
    private environment: AppEnvironment,
    private routerService: IRouterService,
    private getSurveyUseCase: GetSurveyUseCase,
    private getSurveyIdUseCase: GetSurveyIdUseCase,
  ) {}

  async handle(): Promise<InitLanguageResponse> {
    try {
      await this.logService.info('InitLanguageUseCase -> start');
      const surveyId = await this.getSurveyIdUseCase.handle();
      const survey = await this.getSurveyUseCase.handle({ id: surveyId });

      if (survey.languages.length > 1) {
        const queryParam = this.routerService.getQueryParam('language');
        const envLanguage = this.environment.LANGUAGE;
        const language = (queryParam || envLanguage || 'pt-BR') as LocationId;
        this.setCurrentLanguageUseCase.handle(language);
        return;
      }
      this.setCurrentLanguageUseCase.handle(survey.languages[0]?.name);
    } catch (error) {
      throw CustomError.handleError('InitLanguageUseCase', error);
    } finally {
      await this.logService.info('InitLanguageUseCase -> end');
    }
  }
}
