import { LocationId } from '../../../../main/translate/location-id/location-id';
import { LanguageDict } from '../../../../models/common/language-dict/language-dict';
import { ILogService } from '../../common/log/log-service';
import { ITranslateSurveyService } from './translate-survey-service';

export class TranslateSurveyServiceImpl implements ITranslateSurveyService {
  private location: LocationId;
  constructor(private logService: ILogService) {}
  async init(location: LocationId = 'pt-BR'): Promise<void> {
    if (location !== this.location) {
      await this.logService.info('Inicializando tradução para ', location);
      this.location = location;
    }
  }

  translate(languageDict: LanguageDict): string {
    return languageDict.get(this.location);
  }
}
