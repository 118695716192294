import { ISurveyAnswerSessionRepository } from '../../../data/repositories/survey-answers-session/survey-answers-session-repository';
import { ILogService } from '../../../data/services/common/log/log-service';
import { ISurveyAnswerSessionService } from '../../../data/services/survey-answers-session/survey-answers-session-service';
import { CustomError } from '../../../helpers/error/custom-error';
import { Answer } from '../../../models/survey/answer/answer';
import { IAnswerRepository } from './../../../data/repositories/survey/answer/answer-repository';
import { AnswerMapper } from './../../../models/survey/answer/answer-dto';

export type SaveAnswerRequest = Answer;
export type SaveAnswerResponse = void;

export class SaveAnswerUseCase {
  constructor(
    private logService: ILogService,
    private answerRepository: IAnswerRepository,
    private surveyAnswersSessionService: ISurveyAnswerSessionService,
    private surveyAnswerSessionRepository: ISurveyAnswerSessionRepository,
  ) {}

  async handle(request: SaveAnswerRequest): Promise<SaveAnswerResponse> {
    try {
      await this.logService.info('SaveAnswerUseCase -> start');
      await this.logService.debug('SaveAnswerUseCase -> request', request);

      if (!request.isValid()) {
        throw CustomError.invalidContent(['Invalid answer']);
      }

      const answerDTO = AnswerMapper.toDtoFromModel(request);
      this.answerRepository.save(answerDTO);

      const lastSurveyAnswerSession =
        await this.surveyAnswerSessionRepository.getLast('initDate');

      if (
        !lastSurveyAnswerSession ||
        lastSurveyAnswerSession.done ||
        lastSurveyAnswerSession.endDate ||
        lastSurveyAnswerSession.sync
      ) {
        return;
      }

      const answerExist =
        await this.surveyAnswerSessionRepository.getAnswerSession(
          lastSurveyAnswerSession.id,
          answerDTO.id,
        );

      if (answerExist) {
        const indexRemove = lastSurveyAnswerSession.answers?.findIndex(
          (item) => item.id === answerExist.id,
        );
        if (indexRemove !== -1) {
          lastSurveyAnswerSession.answers?.splice(indexRemove, 1);
        }
      }

      if (!lastSurveyAnswerSession.lastQuestionId) {
        lastSurveyAnswerSession.initDate = new Date();
      }

      lastSurveyAnswerSession.lastQuestionId = answerDTO.questionId;
      lastSurveyAnswerSession.leaveQuestionId = request.nextStep?.id;

      lastSurveyAnswerSession.answers.push(answerDTO);
      await this.surveyAnswerSessionRepository.save(lastSurveyAnswerSession);
    } catch (error) {
      throw CustomError.handleError('SaveAnswerUseCase', error);
    } finally {
      await this.logService.info('SaveAnswerUseCase -> end');
    }
  }
}
