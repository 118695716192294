import { Option } from '../../option/option';
import { Answer } from '../answer';

export class AnswerChoices extends Answer {
  protected override canAddOption(option: Option): boolean {
    if (!super.canAddOption(option)) {
      return false;
    }

    const { optionSettings } = this.question || {};
    const { choices } = optionSettings || {};
    const { max } = choices || {};
    const qtSelectedOptions = this.selectedOptions?.length || 0;

    return max == 0 || qtSelectedOptions < max;
  }

  public override isValid() {
    if (!super.isValid()) {
      return false;
    }

    const { optionSettings } = this.question || {};
    const { choices } = optionSettings || {};
    const { min, max } = choices || {};
    const qtSelectedOptions = this.selectedOptions?.length || 0;

    if (max != 0 && qtSelectedOptions > max) {
      return false;
    }

    if (min != 0 && qtSelectedOptions < min && this.question.required) {
      return false;
    }

    if (this.question.required && qtSelectedOptions == 0) {
      return false;
    }

    return true;
  }
}
