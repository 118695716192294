import { makeKeyValueRepository } from '../../../data/repositories/key-value/key-value-repository-factory';
import { makeEventService } from '../../../data/services/common/event/event-service-factory';
import { makeLogService } from '../../../data/services/common/log/log/log-service-factory';
import { makeTranslateSurveyService } from '../../../data/services/common/translate-survey/translate-survey-service-factory';
import { makeTranslateService } from '../../../data/services/common/translate/translate-service-factory';
import { SetCurrentLanguageUseCase } from './set-current-language-use-case';

export function makeSetCurrentLanguageUseCase() {
  return new SetCurrentLanguageUseCase(
    makeLogService(),
    makeTranslateService(),
    makeTranslateSurveyService(),
    makeKeyValueRepository(),
    makeEventService(),
  );
}
