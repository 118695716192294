import { IAnswerRepository } from '../../../data/repositories/survey/answer/answer-repository';
import { ILogService } from '../../../data/services/common/log/log-service';
import { CustomError } from '../../../helpers/error/custom-error';
import { Answer } from '../../../models/survey/answer/answer';
import { AnswerMapper } from '../../../models/survey/answer/answer-dto';
import { Option } from '../../../models/survey/option/option';

export type UnselectOptionRequest = { answer: Answer; option: Option };
export type UnselectOptionResponse = Answer;

export class UnselectOptionUseCase {
  constructor(
    private logService: ILogService,
    private answerRepo: IAnswerRepository,
  ) {}

  async handle(
    request: UnselectOptionRequest,
  ): Promise<UnselectOptionResponse> {
    try {
      await this.logService.info('UnselectOptionUseCase -> start');
      await this.logService.debug('UnselectOptionUseCase -> request', request);

      request.answer.removeOption(request.option);

      await this.answerRepo.save(AnswerMapper.toDtoFromModel(request.answer));

      return request.answer;
    } catch (error) {
      throw CustomError.handleError('UnselectOptionUseCase', error);
    } finally {
      await this.logService.info('UnselectOptionUseCase -> end');
    }
  }
}
