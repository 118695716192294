import { Pipe, PipeTransform } from '@angular/core';
import { makeTranslateSurveyService } from '../../../data/services/common/translate-survey/translate-survey-service-factory';
import { LanguageDict } from '../../../models/common/language-dict/language-dict';

@Pipe({
  name: 'translateSurvey',
  pure: false,
})
export class TranslateSurveyPipe implements PipeTransform {
  translateService = makeTranslateSurveyService();

  transform(languageDict: LanguageDict): string {
    return this.translateService.translate(languageDict);
  }
}
