<div class="main">
  <app-header-question [title]="question.title"></app-header-question>
  <div class="quiz">
    <div class="line-buttons">
      <div class="question" *ngFor="let option of list; let i = index">
        <button
          [style.backgroundColor]="backgroundColor(option)"
          [style.color]="color(option)"
          [style.boxShadow]="boxShadow(option)"
          class="question-button"
          (click)="selectOption(option)"
        >
          {{ option.label | translateSurvey }}
        </button>
      </div>
    </div>

    <app-footer-question
      (clickAction)="sendResponse()"
      [disabled]="!answer?.isValid()"
      *ngIf="showNextQuestion()"
      [textButton]="languageKey.SEND_REPLY"
    ></app-footer-question>
  </div>
  <app-select-language *ngIf="canSelectLanguage"></app-select-language>
</div>
