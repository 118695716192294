import { makeEnvironment } from '../../../environments/make-environment';
import { CustomError } from '../../../helpers/error/custom-error';
import { LocationId } from '../../../main/translate/location-id/location-id';

/**
 * LanguageDict is a class to store a text in different languages
 */
export class LanguageDict {
  private dictionaryMap: Map<string, string> = new Map();

  /**
   *
   * @param language pt-BR, en-US, es-ES ....
   * @param text the text in the language
   */
  set(language: string, text: string) {
    this.dictionaryMap.set(language, text);
  }

  /**
   * Set the text in different languages from an object
   * @param obj {pt-BR: 'texto em português', en-US: 'text in english', es-ES: 'texto en español'}
   */
  setFromObject(obj: object) {
    this.dictionaryMap = new Map(Object.entries(obj));
  }

  /**
   *
   * @param language pt-BR, en-US, es-ES ....
   * @return the text in the language
   */
  get(language?: LocationId): string {
    const env = makeEnvironment();
    if (!language) language = env.LANGUAGE;

    if (!this.dictionaryMap)
      throw CustomError.notFound(['LanguageDict not initialized']);
    if (!this.dictionaryMap.get(language))
      throw CustomError.notFound([`Language ${language} not have text`]);

    return this.dictionaryMap.get(language) || '';
  }
  /**
   * Return a object with the text in different languages
   * @return obj {pt-BR: 'texto em português', en-US: 'text in english', es-ES: 'texto en español'}
   */
  toObject(): { [key: string]: string } {
    const obj = {};
    this.dictionaryMap.forEach((value, key) => {
      obj[key] = value;
    });
    return obj;
  }
}
