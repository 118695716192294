import { CustomError } from '../../../../helpers/error/custom-error';
import { LanguageDict } from '../../../../models/common/language-dict/language-dict';
import { Next } from '../../../../models/survey/next/next';
import { Option } from '../../../../models/survey/option/option';
import { QuestionType } from '../../../../models/survey/question-type/question-type';
import {
  OptionSettings,
  Question,
} from '../../../../models/survey/question/question';
import { Survey } from '../../../../models/survey/survey/survey';
import {
  LoadDictLangDto,
  LoadNextDto,
  LoadOptionDto,
  LoadOptionSettingsDto,
  LoadQuestionDto,
  LoadSurveyDto,
} from './load-survey-dtos';

export class SurveyMapper {
  static toSurveyModel(surveyDto: LoadSurveyDto): Survey {
    const survey = new Survey();
    survey.id = surveyDto.id;
    survey.title = SurveyMapper.toLangDictModel(surveyDto?.title);
    survey.subtitle = SurveyMapper.toLangDictModel(surveyDto.subtitle);
    survey.imgLogo = surveyDto.imgLogo;
    survey.intervalUpdateInMilliseconds =
      surveyDto.intervalUpdateInMilliseconds;
    survey.initial = {
      title: SurveyMapper.toLangDictModel(surveyDto?.initial?.title),
      subtitle: SurveyMapper.toLangDictModel(surveyDto?.initial?.subtitle),
      type: surveyDto?.initial?.type,
      banners: surveyDto?.initial?.banners,
    };
    survey.backgroundColor = surveyDto.backgroundColor;
    survey.textColor = surveyDto.textColor;
    survey.questions = SurveyMapper.toQuestionModel(
      survey.id,
      surveyDto.questions,
    );
    survey.validity = surveyDto.validity;
    survey.languages = surveyDto.languages;
    survey.final = {
      title: SurveyMapper.toLangDictModel(surveyDto.final.title),
      subtitle: SurveyMapper.toLangDictModel(surveyDto.final.subtitle),
    };

    // TODO add firstQuestionId on json

    survey.firstQuestionId =
      surveyDto.firstQuestionId || survey.questions[0].id;

    return survey;
  }

  static fromSurveyModel(survey: Survey): LoadSurveyDto {
    const surveyDto = {} as LoadSurveyDto;

    surveyDto.id = survey.id;
    surveyDto.title = SurveyMapper.fromLangDictModel(survey?.title);
    surveyDto.subtitle = SurveyMapper.fromLangDictModel(survey.subtitle);
    surveyDto.imgLogo = survey.imgLogo;
    surveyDto.backgroundColor = survey.backgroundColor;
    surveyDto.textColor = survey.textColor;
    surveyDto.questions = SurveyMapper.fromQuestionModel(survey.questions);
    surveyDto.validity = survey.validity;
    surveyDto.languages = survey.languages;
    surveyDto.initial = {
      title: SurveyMapper.fromLangDictModel(survey?.initial?.title),
      subtitle: SurveyMapper.fromLangDictModel(survey?.initial?.subtitle),
      type: survey?.initial?.type,
      banners: survey?.initial?.banners,
    };
    surveyDto.firstQuestionId = survey.firstQuestionId;
    surveyDto.final = {
      title: SurveyMapper.fromLangDictModel(survey.final.title),
      subtitle: SurveyMapper.fromLangDictModel(survey.final.subtitle),
    };

    return surveyDto;
  }

  static toLangDictModel(langDictDto: LoadDictLangDto[]): LanguageDict {
    const langDict = new LanguageDict();

    if (!langDictDto) return null;

    langDictDto?.forEach((item) => {
      // TODO check if throw has found
      if (!item.lang || !item.value)
        CustomError.invalidContent(['Language is mallformed']);

      langDict.set(item.lang, item.value);
    });

    return langDict;
  }

  static fromLangDictModel(langDict: LanguageDict): LoadDictLangDto[] {
    const langDictDto: LoadDictLangDto[] = [];

    if (!langDict) return null;

    const langDictObj = langDict.toObject();
    Object.keys(langDictObj).forEach((key) => {
      langDictDto.push({ lang: key, value: langDictObj[key] });
    });

    return langDictDto;
  }

  static toQuestionModel(
    surveyId: string,
    questionDto: LoadQuestionDto[],
  ): Question[] {
    const questionList: Question[] = [];

    if (!questionDto) return questionList;

    questionDto.forEach((item) => {
      const errors = [];
      if (!item.id) errors.push('Question id is missing');
      if (!item.title) errors.push('Question title is missing');
      if (!item.type) errors.push('Question type is missing');
      if (!item.options) errors.push('Question options is missing');
      if (errors.length > 0) CustomError.invalidContent(errors);

      const question = new Question();
      question.id = item.id;
      question.surveyId = surveyId;
      question.title = SurveyMapper.toLangDictModel(item?.title);
      question.subtitle = SurveyMapper.toLangDictModel(item.subtitle);
      question.required = item.required || false;
      question.type = item?.type?.toUpperCase() as QuestionType;
      question.options = SurveyMapper.toOptionModel(question.id, item?.options);
      question.optionSettings = SurveyMapper.toOptionSettingsModel(
        item.optionSettings,
      );

      if (item.next)
        question.next = {
          id: item?.next?.id,
          type: item?.next?.type?.toUpperCase() as Next['type'],
        };
      questionList.push(question);
    });
    return questionList;
  }

  static fromQuestionModel(questionModel: Question[]): LoadQuestionDto[] {
    const questionList: LoadQuestionDto[] = [];

    if (!questionModel) return questionList;

    questionModel.forEach((itemQM) => {
      const questionDto = {} as LoadQuestionDto;
      questionDto.id = itemQM.id;
      questionDto.title = SurveyMapper.fromLangDictModel(itemQM?.title);
      questionDto.subtitle = SurveyMapper.fromLangDictModel(itemQM.subtitle);
      questionDto.required = itemQM.required || false;
      questionDto.type = itemQM.type;
      questionDto.options = SurveyMapper.fromOptionModel(itemQM?.options);
      questionDto.optionSettings = SurveyMapper.fromOptionSettingsModel(
        itemQM.optionSettings,
      );

      if (itemQM.next)
        questionDto.next = {
          id: itemQM?.next?.id,
          type: itemQM?.next?.type?.toUpperCase() as Next['type'],
        };

      questionList.push(questionDto);
    });
    return questionList;
  }

  static fromOptionSettingsModel(
    optionSettings: OptionSettings,
  ): LoadOptionSettingsDto {
    const optionSettingsDto = {} as LoadOptionSettingsDto;

    if (optionSettings.choices) {
      const choices = optionSettings.choices;
      optionSettingsDto.choiceTypeOptions = {
        minimun: choices.min,
        maximun: choices.max,
        next: {} as LoadNextDto,
      };

      if (choices.next?.id) {
        optionSettingsDto.choiceTypeOptions.next = {
          id: choices?.next?.id,
          type: choices?.next?.type?.toUpperCase() as Next['type'],
        };
      }
    }

    if (optionSettings.nps) {
      const nps = optionSettings.nps;
      optionSettingsDto.npsTypeOptions = {
        showOptionScale:
          nps.showOptionScale == true || nps.showOptionScale == 'true'
            ? true
            : false,
        startSubLabel: SurveyMapper.fromLangDictModel(nps.startSubLabel),
        endSubLabel: SurveyMapper.fromLangDictModel(nps.endSubLabel),
      };
    }

    return optionSettingsDto;
  }

  static toOptionSettingsModel(
    loadOptionSettingsDto: LoadOptionSettingsDto,
  ): OptionSettings {
    const optionSettings = {} as OptionSettings;

    if (loadOptionSettingsDto?.npsTypeOptions) {
      const nps = loadOptionSettingsDto.npsTypeOptions;
      optionSettings.nps = {
        showOptionScale:
          nps.showOptionScale == true || nps.showOptionScale == 'true'
            ? true
            : false,
        startSubLabel: SurveyMapper.toLangDictModel(nps.startSubLabel),
        endSubLabel: SurveyMapper.toLangDictModel(nps.endSubLabel),
      };
    }

    if (loadOptionSettingsDto?.choiceTypeOptions) {
      const choices = loadOptionSettingsDto.choiceTypeOptions;
      optionSettings.choices = {
        min: choices.minimun,
        max: choices.maximun,
        next: {} as Next,
      };

      if (choices.next?.id) {
        optionSettings.choices.next = {
          id: choices?.next?.id,
          type: choices?.next?.type?.toUpperCase() as Next['type'],
        };
      }
    }

    return optionSettings;
  }

  static toOptionModel(
    questionId: string,
    optionDto: LoadOptionDto[],
  ): Option[] {
    const optionList: Option[] = [];

    if (!optionDto) return optionList;

    optionDto.forEach((item) => {
      const errors = [];
      if (!item.id) errors.push('Option id is missing');
      if (!item.label) errors.push('Option label is missing');
      if (!item.value) errors.push('Option value is missing');
      if (errors.length > 0) CustomError.invalidContent(errors);

      const option = {} as Option;
      option.id = item.id;
      option.questionId = questionId;
      option.label = SurveyMapper.toLangDictModel(item?.label);
      option.value = item.value;
      if (item.next) {
        option.next = {
          id: item?.next?.id,
          type: item?.next?.type?.toUpperCase() as Next['type'],
        };
      }
      option.picturePath = item.picturePath;
      option.textColorSelected = item.textColorSelected;
      option.textColorUnselected = item.textColorUnselected;
      option.backgroundColorSelected = item.backgroundColorSelected;
      option.backgroundColorUnselected = item.backgroundColorUnselected;

      optionList.push(option);
    });
    return optionList;
  }

  static fromOptionModel(option: Option[]): LoadOptionDto[] {
    const optionListDto: LoadOptionDto[] = [];

    if (!option) return optionListDto;

    option.forEach((item) => {
      const option = {} as LoadOptionDto;
      option.id = item.id;
      option.label = SurveyMapper.fromLangDictModel(item?.label);
      option.value = item.value;
      option.backgroundColorSelected = item.backgroundColorSelected;
      option.backgroundColorUnselected = item.backgroundColorUnselected;
      if (item.next) {
        option.next = {
          id: item?.next?.id,
          type: item?.next?.type?.toUpperCase() as Next['type'],
        };
      }

      optionListDto.push(option);
    });
    return optionListDto;
  }
}
