import { AnswerDto } from '../../../models/survey/answer/answer-dto';
import { SurveyAnswerSession } from '../../../models/survey/survey-answers/survey-answers-session';
import { IIndexedDB } from '../core/indexed-db';
import { DynamicRepositoryImpl } from '../dynamic/dynamic-repository-impl';
import { ISurveyAnswerSessionRepository } from './survey-answers-session-repository';

export class SurveyAnswerSessionRepositoryImpl
  extends DynamicRepositoryImpl<SurveyAnswerSession>
  implements ISurveyAnswerSessionRepository
{
  constructor(private localIndexedDB: IIndexedDB) {
    super(localIndexedDB, 'survey-answers-session');
  }

  async getAnswerSession(
    sessionId: string,
    answerId: string,
  ): Promise<AnswerDto> {
    return Promise.resolve(
      this.table
        .where('id')
        .equals(sessionId)
        .first()
        .then((session: SurveyAnswerSession) => {
          const answerFound = session.answers.find(
            (item) => item.id === answerId,
          );
          if (answerFound) {
            return answerFound;
          }

          return null;
        }),
    );
  }

  getLastOpenSessionBySurveyId(surveyId: string): Promise<SurveyAnswerSession> {
    return this.table
      .where('surveyId')
      .equals(surveyId)
      .filter(
        (session: SurveyAnswerSession) =>
          !session.done && !session.leave && !session.sync,
      )
      .first();
  }

  getOpenSessionsBySurveyId(surveyId: string): Promise<SurveyAnswerSession[]> {
    return this.table
      .where('surveyId')
      .equals(surveyId)
      .filter(
        (session: SurveyAnswerSession) =>
          !session.done && !session.leave && !session.sync,
      )
      .toArray();
  }

  getAbandonedSessions(): Promise<SurveyAnswerSession[]> {
    return this.table
      .filter(
        (session: SurveyAnswerSession) =>
          !session.done && session.leave && !session.sync,
      )
      .toArray();
  }

  getDoneSessions(limit?: number): Promise<SurveyAnswerSession[]> {
    const tableResult = limit ? this.table.limit(limit) : this.table;

    return tableResult
      .filter((session: SurveyAnswerSession) => session.done && !session.sync)
      .toArray();
  }

  getDoneSessionsWithoutAnswer(limit?: number): Promise<SurveyAnswerSession[]> {
    const tableResult = limit ? this.table.limit(limit) : this.table;

    return tableResult
      .filter(
        (session: SurveyAnswerSession) =>
          session.done && !session.sync && !session.answers.length,
      )
      .toArray();
  }
}
