import { IAnswerRepository } from 'src/data/repositories/survey/answer/answer-repository';
import { CustomError } from '../../../helpers/error/custom-error';
import { SurveyAnswerSession } from '../../../models/survey/survey-answers/survey-answers-session';
import { ISurveyAnswerSessionRepository } from '../../repositories/survey-answers-session/survey-answers-session-repository';
import { ILogService } from '../common/log/log-service';
import { ISurveyAnswerSessionService } from './survey-answers-session-service';

export class SurveyAnswerSessionServiceImpl
  implements ISurveyAnswerSessionService
{
  public lastSurveyAnswerSession: SurveyAnswerSession;

  constructor(
    private logService: ILogService,
    private surveyAnswerSessionRepository: ISurveyAnswerSessionRepository,
    private answerRepository: IAnswerRepository,
  ) {}

  async getSession(surveyId: string): Promise<SurveyAnswerSession> {
    const session = await this.getLastOpenSessionBySurveyId(surveyId);
    if (session && !session.done && !session.endDate && !session.sync) {
      session.answers = await this.answerRepository.getAnswerBySession(
        session.id,
      );
      this.logService.debug(
        'SurveyAnswerSessionServiceImpl -> getSession -> recovery session',
        session.id,
      );
      return session;
    }
    this.logService.debug(
      'SurveyAnswerSessionServiceImpl -> getSession -> create session',
    );
    return await this.createSession(surveyId);
  }

  async createSession(surveyId: string): Promise<SurveyAnswerSession> {
    await this.abandonOldSessions(surveyId);
    await this.checkAbandonedSessionAndFinish();

    const surveyAnswerSession: SurveyAnswerSession = {
      id: '',
      surveyId,
      lastQuestionId: '',
      initDate: new Date(),
      endDate: null,
      done: false,
      leave: false,
      sync: false,
      answers: [],
      leaveQuestionId: null,
    };

    surveyAnswerSession.id = await this.saveSession(surveyAnswerSession);

    return surveyAnswerSession;
  }

  async endSession(surveyAnswerSession?: SurveyAnswerSession): Promise<void> {
    this.lastSurveyAnswerSession =
      surveyAnswerSession || (await this.getLastSession());

    if (!this.lastSurveyAnswerSession) {
      return;
    }

    this.lastSurveyAnswerSession.done = true;
    this.lastSurveyAnswerSession.endDate = new Date();
    this.lastSurveyAnswerSession.leaveQuestionId = null;
    await this.saveSession(this.lastSurveyAnswerSession);
  }

  async leaveSession(surveyAnswerSession?: SurveyAnswerSession): Promise<void> {
    this.lastSurveyAnswerSession =
      surveyAnswerSession || (await this.getLastSession());

    if (!this.lastSurveyAnswerSession) {
      return;
    }
    this.lastSurveyAnswerSession.leave = true;
    await this.saveSession(this.lastSurveyAnswerSession);
  }

  async saveSession(surveyAnswerSession: SurveyAnswerSession): Promise<string> {
    try {
      const result = await this.surveyAnswerSessionRepository.save(
        surveyAnswerSession,
      );
      return result || '';
    } catch (error) {
      await this.logService.debug(
        'SurveyAnswerSessionServiceImpl.saveSession -> error',
        error,
      );
      throw CustomError.handleError(
        error,
        'SurveyAnswerSessionServiceImpl.saveSession',
      );
    }
  }

  async getLastOpenSessionBySurveyId(
    surveyId: string,
  ): Promise<SurveyAnswerSession | undefined> {
    return await this.surveyAnswerSessionRepository.getLastOpenSessionBySurveyId(
      surveyId,
    );
  }

  async abandonOldSessions(surveyId: string): Promise<void> {
    const openedSessions =
      await this.surveyAnswerSessionRepository.getOpenSessionsBySurveyId(
        surveyId,
      );
    const abandonedSessions = openedSessions.map(
      (item: SurveyAnswerSession) => {
        return { ...item, leave: true, endDate: new Date() };
      },
    );

    if (abandonedSessions?.length) {
      await this.surveyAnswerSessionRepository.saveList(abandonedSessions);
    }
  }
  async checkAbandonedSessionAndFinish(): Promise<void> {
    const abandonedSessions =
      await this.surveyAnswerSessionRepository.getAbandonedSessions();

    const finalizedSessions = abandonedSessions.map(
      (item: SurveyAnswerSession) => {
        return { ...item, done: true, endDate: new Date() };
      },
    );

    if (finalizedSessions?.length) {
      await this.surveyAnswerSessionRepository.saveList(finalizedSessions);
    }
  }

  async updateInitDateSession(
    surveyAnswerSession?: SurveyAnswerSession,
  ): Promise<void> {
    this.lastSurveyAnswerSession =
      surveyAnswerSession || (await this.getLastSession());

    if (this.lastSurveyAnswerSession) {
      this.lastSurveyAnswerSession.initDate = new Date();
      await this.saveSession(this.lastSurveyAnswerSession);
    }
  }

  private async getLastSession(): Promise<SurveyAnswerSession> {
    return await this.surveyAnswerSessionRepository.getLast('initDate');
  }
}
