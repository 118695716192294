import { makeLogService } from '../../common/log/log/log-service-factory';
import { ITranslateSurveyService } from './translate-survey-service';
import { TranslateSurveyServiceImpl } from './translate-survey-service-impl';

let translateSurveyService: ITranslateSurveyService;

export function makeTranslateSurveyService() {
  if (!translateSurveyService) {
    translateSurveyService = new TranslateSurveyServiceImpl(makeLogService());
  }
  return translateSurveyService;
}
