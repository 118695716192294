<div class="main">
  <app-header-question [title]="question.title"></app-header-question>
  <div class="quiz">
    <div class="line-buttons">
      <div class="question" *ngFor="let option of list">
        <button
          [style.backgroundColor]="backgroundColor(option)"
          [style.border]="border(option)"
          [style.color]="color(option)"
          [style.boxShadow]="boxShadow()"
          class="question-button"
          (click)="selectOption(option)"
        >
          {{ option.label | translateSurvey }}
        </button>
      </div>
    </div>

    <app-footer-question
      [disabled]="!answer?.isValid()"
      (clickAction)="sendResponse()"
      [textButton]="languageKey.NEXT"
    ></app-footer-question>
  </div>
  <app-select-language *ngIf="canSelectLanguage"></app-select-language>
</div>
