import { CustomError } from '../../../helpers/error/custom-error';
import { Survey } from '../../../models/survey/survey/survey';
import { ISurveyRepository } from '../../repositories/survey/survey-repository';
import { ILogService } from '../common/log/log-service';
import { LoadSurveyDto } from '../download-survey/dto/load-survey-dtos';
import { SurveyMapper } from '../download-survey/dto/survey-mapper';
import { IGetSurveyService } from './get-survey-service';

export class GetSurveyServiceImpl implements IGetSurveyService {
  constructor(
    private logService: ILogService,
    private surveyRepo: ISurveyRepository,
  ) {}

  async getSurvey(surveyId: string): Promise<Survey> {
    try {
      const surveyData = await this.surveyRepo.getById(surveyId);

      const surveyModel = surveyData
        ? SurveyMapper.toSurveyModel(surveyData as unknown as LoadSurveyDto)
        : null;
      return surveyModel;
    } catch (error) {
      throw CustomError.handleError('GetSurveyServiceImpl', error);
    }
  }

  async getAllSurveys(): Promise<Survey[]> {
    const surveyDataList = await this.surveyRepo.getAll();

    const surveys = surveyDataList.map((surveyData) =>
      SurveyMapper.toSurveyModel(surveyData as unknown as LoadSurveyDto),
    );
    return surveys;
  }
}
