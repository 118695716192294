import { CustomError } from '../../../helpers/error/custom-error';
import { Survey } from '../../../models/survey/survey/survey';
import { ISurveyRepository } from '../../repositories/survey/survey-repository';
import { ILogService } from '../common/log/log-service';
import { IFetchSurveyService } from '../net/fetch-survey/fetch-survey-service';
import { IDownloadSurveyService } from './download-survey-service';
import { SurveyMapper } from './dto/survey-mapper';

export class DownloadSurveyServiceImpl implements IDownloadSurveyService {
  constructor(
    private logService: ILogService,
    private fetchSurveyService: IFetchSurveyService,
    private surveyRepository: ISurveyRepository,
  ) {}

  async load(id: string): Promise<Survey | false> {
    await this.logService.info('DownloadSurveyServiceImpl.load');

    const surveyJson = await this.fetchSurveyService.fetchSurvey(id);

    if (!surveyJson) {
      return false;
    }

    const surveyDto =
      typeof surveyJson === 'string' ? JSON.parse(surveyJson) : surveyJson;

    if (!surveyDto) {
      throw CustomError.invalidContent(['Survey is empty']);
    }
    const survey = SurveyMapper.toSurveyModel(surveyDto);

    const surveyToSave = SurveyMapper.fromSurveyModel(survey);

    await this.logService.debug(
      'DownloadSurveyServiceImpl.load -> surveyAsModel',
      survey,
    );

    this.surveyRepository.save(surveyToSave);

    return survey;
  }
}
