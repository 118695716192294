import { makeLogService } from '../../../data/services/common/log/log/log-service-factory';
import { makeDownloadSurveyUseCase } from '../../survey/download-survey/download-survey-use-case-factory';
import { makeGetSurveyIdUseCase } from '../../survey/get-survey-id/get-survey-id-use-case-factory';
import { UpdateForceSurveyUseCase } from './update-force-survey-use-case';

export function makeUpdateForceSurveyUseCase() {
  return new UpdateForceSurveyUseCase(
    makeLogService(),
    makeDownloadSurveyUseCase(),
    makeGetSurveyIdUseCase(),
  );
}
