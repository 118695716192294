import { ILogService } from '../../../data/services/common/log/log-service';
import { CustomError } from '../../../helpers/error/custom-error';
import { Answer } from '../../../models/survey/answer/answer';
import { SaveAnswerUseCase } from '../save-answer/save-answer-use-case';

export type SkipQuestionRequest = Answer;
export type SkipQuestionResponse = void;

export class SkipQuestionUseCase {
  constructor(
    private logService: ILogService,
    private saveAnswerUseCase: SaveAnswerUseCase,
  ) {}

  async handle(request: SkipQuestionRequest): Promise<SkipQuestionResponse> {
    try {
      await this.logService.info('SkipQuestionUseCase -> start');
      await this.logService.debug('SkipQuestionUseCase -> request', request);

      this.saveAnswerUseCase.handle(request);
    } catch (error) {
      throw CustomError.handleError('SkipQuestionUseCase', error);
    } finally {
      await this.logService.info('SkipQuestionUseCase -> end');
    }
  }
}
